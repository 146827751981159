/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { FormField } from "../../../../models/Process";
import { FBContext } from "../context/FormBuilderContext";

interface ParagraphControlProps {
  field: FormField;
  sectionId: string;
}

export default function ParagraphControl(props: ParagraphControlProps): ReactElement {
  const formBuilderContext = useContext(FBContext);

  const paragraphText = useRef<HTMLInputElement>(null);

  const [showSettings, setShowSettings] = useState(false);

  const handleDelete = () => {
    formBuilderContext.dispatch({
      type: "DELETE_FIELD",
      payload: {
        sectionId: props.sectionId,
        fieldId: props.field.id,
      },
    });
  };

  const handleSaveChanges = () => {

    formBuilderContext.dispatch({
      type: "UPDATE_FIELD",
      payload: {
        sectionId: props.sectionId,
        field:{
          ...props.field,
          value: paragraphText.current?.value ?? "Empty paragraph"
        },
      },
    });

    setShowSettings(false);
  };

  return (
    <div>
      <div
        className="card card-custom draggable"
        style={{ marginBottom: "2rem" }}
      >
        <div className="card-body" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row" style={{alignItems: 'center'}}>
              <div className="col-11">
                <p style={{ padding: "10px" }} className="text-black-50">
                  {props.field.value ?? "Empty paragraph"}
                </p>
              </div>

              <div className="col-1">
                <div className="d-flex flex-column">
                  <div className="p-2 dragHandleControl">
                    <button className="btn btn-sm btn-icon">
                      <i className="las la-bars fs-3"></i>
                    </button>
                  </div>
                  <div className="p-2">
                    <button
                      className="btn  btn-sm btn-icon"
                      onClick={() => setShowSettings(true)}
                    >
                      <i className="las la-cog fs-3"></i>
                    </button>
                  </div>
                  <div className="p-2">
                    <button
                      className="btn btn-sm btn-icon"
                      onClick={() => handleDelete()}
                    >
                      <i className="las la-trash fs-3"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showSettings} centered>
        <Modal.Header closeButton>
          <Modal.Title>Field settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Control label:</label>
            <input
              type="text"
              multiple
              className="form-control"
              placeholder="Enter value"
              ref={paragraphText}
              defaultValue={props.field.name}
            />
            <span className="form-text text-muted">
              Label text for this control
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSettings(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSaveChanges()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
