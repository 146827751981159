import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FVContext } from "../context/FormViewerContext";

interface Props {
  title: string;
  description?: string;
  id: string;
  value?: string;
  confirmed?: boolean;
  onChange: (fieldId: string, fieldValue: string) => void;
  onLocked: (fieldId: string, locked: boolean) => void;
  formId?: string;
}

export default function FVTextField(props: Props): ReactElement {
  const fvContext = useContext(FVContext);

  const changeAction = fvContext.state.changeNotifications.find(
    (x) => x.fieldId === props.id
  );

  const [value, setValue] = useState(props.value ? props.value : "");

  const [locked, setLocked] = useState(
    props.confirmed ? props.confirmed : false
  );

  const onConfirmedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLocked(event.target.checked);
    props.onLocked(props.id, event.target.checked);
  };

  const changeValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    props.onChange(props.id, event.target.value);
  };

  useEffect(() => {
    setValue(props.value ? props.value : "");
  }, [props.value]);

  return (
    <div className="form-group row align-items-center p-3 my-1">
      <div className="col-lg-11">
        <label>{props.title}</label>
        <input
          disabled={locked || changeAction !== undefined}
          onChange={changeValueHandler}
          value={value}
          type="text"
          className="form-control"
          placeholder="Enter value"
        />
        <span className="form-text text-muted">
          {changeAction === undefined
            ? props.description
              ? props.description
              : ""
            : ` bearbeiten von ${changeAction.userName} am ${ (new Date(changeAction.time)).toLocaleTimeString()}`}
        </span>
      </div>
      <div className="col-lg-1">
        <input
          className="form-check-input"
          onChange={onConfirmedChange}
          type="checkbox"
          name="select"
          checked={locked}
        />
      </div>
    </div>
  );
}
