import React from "react";

export function Login() {
  
  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.href = "/.auth/login/aad?post_login_redirect_uri=/";
  };

  return (
    <form
      className="form w-100"
      noValidate
      id="kt_login_signin_form"
      onSubmit={handleLogin}
    >
      {/* begin::Title */}
      <div className="pb-lg-15">
        <h3 className="fw-bolder text-dark display-6">Willkommen bei ALBIRO Prozesse</h3>
      </div>
      {/* begin::Title */}



      {/* begin::Action */}
      <div className="pb-lg-0 pb-5">
        <button
          type="submit"
          id="kt_login_signin_form_submit_button"
          className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3 w-100"
        >
          <span className="indicator-label">Sign In</span>
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}

