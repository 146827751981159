/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Paging } from "../../../../../models/paging";
import { Process } from "../../../../../models/Process";
import { SuperStoreService } from "../../../../../services/SuperStoreService";
import { calculatePageSize } from "../../../../../utils/listUtils";

export type TWRContextState = {
  processes: Process[];
  paging: Paging;
  loading: boolean;
  error: string;
  pageNumber: number;
  totalCount: number;
  itemsPerPage: number;
};

export type TWRContextAction = {
  type:
    | "PAGE_FORWARD"
    | "PAGE_BACKWARD"
    | "SET_PAGE"
    | "SET_PROCESSES"
    | "SET_ERROR"
    | "SET_LOADING"
    | "SET_TOTAL_COUNT"
    | "DELETE_PROCESS"
    | "RESTORE_PROCESS";
  payload?: Process[] | Paging | string | boolean | number | Process;
};

export type TWRContextType = {
  state: TWRContextState;
  dispatch: React.Dispatch<TWRContextAction>;
};

export const TWRContext = React.createContext<TWRContextType>({
  state: {
    processes: [],
    paging: {
      take: 10,
      skip: 0,
      filter: "true",
      filterBy: "deleted",
    },
    loading: false,
    error: "",
    pageNumber: 0,
    totalCount: 0,
    itemsPerPage: 10,
  },
  dispatch: () => {},
});

const initialState: TWRContextState = {
  processes: [],
  paging: {
    take: 10,
    skip: 0,
    filter: "true",
    filterBy: "deleted",
  },
  loading: false,
  error: "",
  pageNumber: 0,
  totalCount: 0,
  itemsPerPage: calculatePageSize(),
};

export const useTWRContext = () => {
  const [state, dispatch] = React.useReducer(
    (state: TWRContextState, action: TWRContextAction) => {
      switch (action.type) {
        case "PAGE_FORWARD":
          if (state.paging.skip + state.paging.take < state.totalCount) {
            return {
              ...state,
              pageNumber: state.pageNumber + 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip + state.paging.take,
              },
            };
          } else return state;
        case "PAGE_BACKWARD":
          if (state.paging.skip - state.paging.take >= 0) {
            return {
              ...state,
              pageNumber: state.pageNumber - 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip - state.paging.take,
              },
            };
          } else return state;
        case "SET_PAGE":
          return {
            ...state,
            paging: {
              ...state.paging,
              skip: 0,
            },
          };
        case "SET_PROCESSES":
          return {
            ...state,
            processes: action.payload as Process[],
          };
        case "SET_ERROR":
          return {
            ...state,
            error: action.payload as string,
          };
        case "SET_LOADING":
          return {
            ...state,
            loading: action.payload as boolean,
          };
        case "SET_TOTAL_COUNT":
          return {
            ...state,
            totalCount: action.payload as number,
          };
        case "DELETE_PROCESS":
          const _client = SuperStoreService.getInstance();
          _client.deleteItem("process", action.payload as string);
          return {
            ...state,
            processes: state.processes.filter(
              (process) => process.id !== action.payload
            ),
          };
        case "RESTORE_PROCESS":
          const _process = state.processes.find(
            (process) => process.id === action.payload
          );
          if (_process) {
            _process.deleted = false;
            const _client2 = SuperStoreService.getInstance();
            _client2.upsertItem("process", _process as Process, _process.id!);
          }
          return {
            ...state,
            processes: state.processes.filter(
              (process) => process.id !== action.payload
            ),
          };
        default:
          return state;
      }
    },
    initialState
  );

  React.useEffect(() => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });

    Promise.all([fetchProcesses(), fetchTotalCount()])
      .then(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        dispatch({
          type: "SET_ERROR",
          payload: "Error fetching data",
        });
      });

    return () => {};
  }, [state.paging]);

  const fetchProcesses = async () => {
    try {
      const _client = SuperStoreService.getInstance();
      const _processes = await _client.getItems<Process>(
        state.paging,
        "process"
      );
      dispatch({
        type: "SET_PROCESSES",
        payload: _processes,
      });
    } catch (error) {}
  };

  const fetchTotalCount = async () => {
    try {
      const _client = SuperStoreService.getInstance();
      const _totalCount = await _client.getItemsCount("process", state.paging);
      dispatch({
        type: "SET_TOTAL_COUNT",
        payload: _totalCount,
      });
    } catch (error) {}
  };

  return {
    state,
    dispatch,
  };
};
