/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FormTemplate } from "../../../../../models/FormTemplate";
import { Paging } from "../../../../../models/paging";
import { SuperStoreService } from "../../../../../services/SuperStoreService";
import { calculatePageSize } from "../../../../../utils/listUtils";

export type TWTContextState = {
  processes: FormTemplate[];
  paging: Paging;
  loading: boolean;
  error: string;
  pageNumber: number;
  totalCount: number;
  itemsPerPage: number;
};

export type TWTContextAction = {
  type:
    | "PAGE_FORWARD"
    | "PAGE_BACKWARD"
    | "SET_PAGE"
    | "SET_FORMTEMPLATES"
    | "SET_ERROR"
    | "SET_LOADING"
    | "SET_TOTAL_COUNT";
  payload?: FormTemplate[] | Paging | string | boolean | number;
};

export type TWTContextType = {
  state: TWTContextState;
  dispatch: React.Dispatch<TWTContextAction>;
};

export const TWTContext = React.createContext<TWTContextType>({
  state: {
    processes: [],
    paging: {
      take: 10,
      skip: 0,
    },
    loading: false,
    error: "",
    pageNumber: 0,
    totalCount: 0,
    itemsPerPage: 10,
  },
  dispatch: () => {},
});

const initialState: TWTContextState = {
  processes: [],
  paging: {
    take: 10,
    skip: 0,
  },
  loading: false,
  error: "",
  pageNumber: 0,
  totalCount: 0,
  itemsPerPage: calculatePageSize(),
};

export const useTWTContext = () => {
  const [state, dispatch] = React.useReducer(
    (state: TWTContextState, action: TWTContextAction) => {
      switch (action.type) {
        case "PAGE_FORWARD":
          if (state.paging.skip + state.paging.take < state.totalCount) {
            return {
              ...state,
              pageNumber: state.pageNumber + 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip + state.paging.take,
              },
            };
          } else return state;
        case "PAGE_BACKWARD":
          if (state.paging.skip - state.paging.take >= 0) {
            return {
              ...state,
              pageNumber: state.pageNumber - 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip - state.paging.take,
              },
            };
          } else return state;
        case "SET_PAGE":
          return {
            ...state,
            paging: {
              ...state.paging,
              skip: 0,
            },
          };
        case "SET_FORMTEMPLATES":
          return {
            ...state,
            processes: action.payload as FormTemplate[],
          };
        case "SET_ERROR":
          return {
            ...state,
            error: action.payload as string,
          };
        case "SET_LOADING":
          return {
            ...state,
            loading: action.payload as boolean,
          };
        case "SET_TOTAL_COUNT":
          return {
            ...state,
            totalCount: action.payload as number,
          };
        default:
          return state;
      }
    },
    initialState
  );

  React.useEffect(() => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });

    Promise.all([fetchProcesses(dispatch, state), fetchTotalCount(dispatch)])
      .then(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        dispatch({
          type: "SET_ERROR",
          payload: "Error fetching data",
        });
      });

    return () => {};
  }, [state.paging]);

  return {
    state,
    dispatch,
  };
};

const fetchProcesses = async (
  dispatch: React.Dispatch<TWTContextAction>,
  state: TWTContextState
) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _items = await _client.getItems<FormTemplate>(
      state.paging,
      "formtemplate"
    );
    dispatch({
      type: "SET_FORMTEMPLATES",
      payload: _items,
    });
  } catch (error) {}
};

const fetchTotalCount = async (dispatch: React.Dispatch<TWTContextAction>) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _totalCount = await _client.getItemsCount("formtemplate");
    dispatch({
      type: "SET_TOTAL_COUNT",
      payload: _totalCount,
    });
  } catch (error) {}
};

export const deleteTemplate = async (
  dispatch: React.Dispatch<TWTContextAction>,
  state: TWTContextState,
  id: string
) => {
  try {
    const _client = SuperStoreService.getInstance();
    await _client.deleteItem("formtemplate", id);

    fetchProcesses(dispatch, state);
    fetchTotalCount(dispatch);
  } catch (error) {}
};
