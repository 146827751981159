/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { FormField } from "../../../../models/Process";
import { FBContext } from "../context/FormBuilderContext";

interface TextControlProps {
  field: FormField;
  sectionId: string;
}

export default function TextControl(props: TextControlProps): ReactElement {
  const formBuilderContext = useContext(FBContext);

  const [showSettings, setShowSettings] = useState(false);
  const fieldName = useRef<HTMLInputElement>(null);
  const fieldDescription = useRef<HTMLInputElement>(null);

  const handleDelete = () => {
    formBuilderContext.dispatch({
      type: "DELETE_FIELD",
      payload: {
        sectionId: props.sectionId,
        fieldId: props.field.id,
      },
    });
  };

  const handleSaveChanges = () => {
    const name = fieldName.current?.value;
    const description = fieldDescription.current?.value;

    formBuilderContext.dispatch({
      type: "UPDATE_FIELD",
      payload: {
        sectionId: props.sectionId,
        field: {
          ...props.field,
          name: name ?? "Empty name",
          description: description ?? "",
        },
      },
    });

    setShowSettings(false);
  };

  return (
    <div>
      <div
        className="card card-custom draggable"
        style={{ marginBottom: "2rem" }}
      >
        <div className="card-body" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-11">
                <div
                  className="d-flex flex-column"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="form-group">
                    <label className="form-label">{props.field.name}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter value"
                    />
                    <span className="form-text text-muted">
                      {props.field.description}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-1">
                <div className="d-flex flex-column">
                  <div className="p-2 dragHandleControl">
                    <button className="btn btn-sm btn-icon">
                      <i className="las la-bars fs-3"></i>
                    </button>
                  </div>
                  <div className="p-2">
                    <button
                      className="btn  btn-sm btn-icon"
                      onClick={() => setShowSettings(true)}
                    >
                      <i className="las la-cog fs-3"></i>
                    </button>
                  </div>
                  <div className="p-2">
                    <button
                      className="btn btn-sm btn-icon"
                      onClick={() => handleDelete()}
                    >
                      <i className="las la-trash fs-3"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showSettings} centered>
        <Modal.Header closeButton>
          <Modal.Title>Field settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Control label:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter value"
              ref={fieldName}
              defaultValue={props.field.name}
            />
            <span className="form-text text-muted">
              Label text for this control
            </span>
          </div>

          <div className="form-group">
            <label className="form-label">Control description:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter value"
              ref={fieldDescription}
              defaultValue={props.field.description}
            />
            <span className="form-text text-muted">
              A description for the field
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSettings(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSaveChanges()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
