/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

type DatasetRowProps = {
    datasetTitle: string;
    datasetDescription: string;
    datasetOwner: string;
    datasetDate: string;
    datasetId: string;
    handleDelete : (id: string) => void;
}

export default function DatasetRow(props: DatasetRowProps) {

  const navigate = useNavigate();

  return (
    <tr>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6" onClick={() => navigate(`../dataseteditor/${props.datasetId}`)}>
          {props.datasetTitle}
        </a>
        <span className="text-muted fw-bold d-block mt-1">
          {props.datasetDescription}
        </span>
      </td>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          <span className="text-muted">
          {props.datasetOwner}
          </span>
        </a>
      </td>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          {props.datasetDate}
        </a>
      </td>
      <td className="text-end d-flex align-items-center justify-content-end gap-3 mt-2">
      <a onClick={() => navigate(`../dataseteditor/${props.datasetId}`)} href="#" className="btn btn-icon btn-light-primary btn-sm"><i className="las la-edit fs-2"></i></a>
      <a onClick={() => props.handleDelete(props.datasetId)} href="#" className="btn btn-icon btn-light-primary btn-sm"><i className="las la-trash fs-2"></i></a>
      </td>
    </tr>
  );
}
