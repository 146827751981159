import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { DatasetEditorContext } from "../context/DatasetEditorContext";

interface TermItemProps {
  term: string;
  index: number;
}

export default function TermItem(props: TermItemProps) {
  const deContext = useContext(DatasetEditorContext);

  const [showDelete, setShowDelete] = useState(false);

  return (
    <div
      className="d-flex bg-light-info text-info my-1"
      style={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <div className="d-flex" style={{ alignItems: "center" }}>
        <div className="p-3 dragHandleControl">
          <button className="btn btn-sm btn-icon">
            <i className="las la-bars fs-3"></i>
          </button>
        </div>
        <div className="">{props.term}</div>
      </div>
      <div className="mx-3">
        <button
          className="btn btn-sm btn-icon"
          onClick={() => setShowDelete(true)}
        >
          <i className="las la-trash fs-3"></i>
        </button>
      </div>
      <Modal
        onHide={() => {
          setShowDelete(false);
        }}
        show={showDelete}
        centered
      >
        <Modal.Header>
          <Modal.Title>Begfriff entfernen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sind sie sicher dass der Begriff <b>"{props.term}"</b> entfernt werden soll?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Schliessen
          </Button>
          <Button variant="primary" onClick={() => {
            deContext.dispatch({
              type: "DELETE_TERM",
              payload: {
                term: props.term
              },
            });
            setShowDelete(false);
          }}>
            Loschen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
