import React from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../helpers";

interface CreateFolderModalProps {
  show: boolean;
  handleClose: () => void;
  handleCreate: (folderName: string) => void;
}

export default function CreateFolderModal(props: CreateFolderModalProps) {

  const folderName = React.useRef<HTMLInputElement>(null);

  return (
    <Modal
      id="kt_modal_1"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered h-auto"
      show={props.show}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Ordner erstellen</h5>
          <div
            onClick={() => props.handleClose()}
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <KTSVG
              path="/media/icons/duotone/Navigation/Close.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="mb-10">
            <label className="form-label">Namen des Ordners angeben:</label>
            <input
              ref={folderName}
              type="text"
              className="form-control"
              placeholder="z.B. Daten"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={() => props.handleClose()}
          >
            Schliessen
          </button>
          <button
            onClick={() => props.handleCreate(folderName.current?.value!)}
            type="button"
            className="btn btn-primary"
          >
            Erstellen
          </button>
        </div>
      </div>
    </Modal>
  );
}
