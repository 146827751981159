/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserFile } from "../../../models/UserFile";
import { downloadFile } from "../../../utils/fileUtils";
import { KTSVG } from "../../../_start/helpers";
import FileElement from "./components/FileElement";
import FormSection from "./components/FormSection";
import FVDropdown from "./components/FVDropdown";
import FVParagraph from "./components/FVParagraph";
import FVTextField from "./components/FVTextField";
import {
  attachFile,
  FVContext,
  useFVContext,
} from "./context/FormViewerContext";
import UpdateFileModal from "./modal/UpdateFileModal";
import ViewHistoryModal from "./modal/ViewHistoryModal";

export const FormViewerPage: React.FC = () => {
  const { processId } = useParams();

  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const [showHistoryDialog, setShowHistoryDialog] = useState(false);

  const context = useFVContext(processId as string);

  const navigate = useNavigate();

  const handleFieldChanged = (id: string, value: string) => {
    context.dispatch({
      type: "UPDATE_FIELD_VALUE",
      payload: {
        fieldId: id,
        value: value,
        processId: context.state.process!.data.id,
      },
    });
  };

  const handleFieldLocked = (id: string, value: boolean) => {
    context.dispatch({
      type: "UPDATE_LOCK",
      payload: {
        id: id,
        value: value,
      },
    });
  };

  const handleSave = () => {
    context.dispatch({
      type: "UNLOCK_PROCESS",
    });

    context.dispatch({
      type: "SAVE_PROCESS",
    });

    navigate("/");
  };

  const handleLock = () => {
    if (!context.state.process!.locked) {
      context.dispatch({
        type: "LOCK_PROCESS",
      });
      context.dispatch({
        type: "SAVE_PROCESS",
      });
    }
    if (context.state.process!.locked) {
      context.dispatch({
        type: "UNLOCK_PROCESS",
      });
      context.dispatch({
        type: "SAVE_PROCESS",
      });
    }
  };

  return (
    <FVContext.Provider value={context}>
      {/* begin::Row */}
      {context.state.process && (
        <div className="col-xl-12">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <span className="card-icon">
                  <i className="flaticon-list-2 text-primary"></i>
                </span>
                <h3 className="card-label d-flex flex-column">
                  {context.state.process!.data.name}
                  <small>{context.state.process!.data.template}</small>
                </h3>
              </div>
              <div className="card-toolbar">
                <a
                  style={{ marginRight: "10px" }}
                  onClick={() => handleLock()}
                  className="btn btn-sm btn-success font-weight-bold"
                >
                  <i className="las la-lock fs-1x"></i>
                  {context.state.process!.locked ? "Entsperren" : "Sperren"}
                </a>
                <a
                  style={{ marginRight: "10px" }}
                  onClick={() => setShowHistoryDialog(true)}
                  className="btn btn-sm btn-success font-weight-bold"
                >
                  <i className="flaticon2-cube"></i> Show history
                </a>
                <a
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setShowUploadDialog(true);
                  }}
                  className="btn btn-sm btn-success font-weight-bold"
                >
                  <i className="flaticon2-cube"></i> Datei hinzufuegen
                </a>
                <a
                  onClick={handleSave}
                  className="btn btn-sm btn-success font-weight-bold"
                >
                  <i className="flaticon2-cube"></i> Save
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex flex-row justify-content-between">
                <div>
                  Gestartet von: {context.state.process!.data.createdBy}{" "}
                  <br></br>
                  Gestartet am:{" "}
                  {new Date(
                    context.state.process!.data.created
                  ).toLocaleDateString()}{" "}
                  |{" "}
                  {new Date(
                    context.state.process!.data.created
                  ).toLocaleTimeString()}{" "}
                  <br></br>
                </div>
                <div
                  className="d-flex"
                  style={{ maxWidth: "26rem", overflow: "scroll" }}
                >
                  {context.state.process.data.content.attachments &&
                    context.state.process.data.content.attachments.map(
                      (item: UserFile, index: number) => {
                        return <FileElement key={index} file={item} />;
                      }
                    )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "4rem" }}
            className="accordion accordion-toggle-arrow"
            id="formvieweraccordion"
          >
            {context.state.process!.data.content.sections.map(
              (newSection: any, index: number) => {
                return (
                  <FormSection
                    key={index}
                    dueDate={newSection.dueDate}
                    owners={newSection.owners ?? []}
                    title={newSection.name}
                    id={newSection.id ? newSection.id : ""}
                  >
                    <div id="fields1">
                      {newSection.fields1.map(
                        (newField: any, index: number) => {
                          if (newField.type === "textbox") {
                            return (
                              <FVTextField
                                key={index}
                                onChange={handleFieldChanged}
                                onLocked={handleFieldLocked}
                                id={newField.id}
                                value={newField.value ? newField.value : ""}
                                description={newField.description}
                                title={newField.name}
                                confirmed={newField.locked}
                                formId={context.state.process?.id}
                              ></FVTextField>
                            );
                          }
                          if (newField.type === "dropdown") {
                            return (
                              <FVDropdown
                                key={index}
                                onChange={handleFieldChanged}
                                onLocked={handleFieldLocked}
                                description={newField.description}
                                id={newField.id}
                                properties={
                                  newField.options
                                    ? newField.options
                                    : ["No options"]
                                }
                                value={newField.value ? newField.value : ""}
                                title={newField.name}
                                confirmed={newField.locked}
                              ></FVDropdown>
                            );
                          }
                          if (newField.type === "paragraph") {
                            return (
                              <FVParagraph
                                key={index}
                                id={newField.id}
                                paragraphText={
                                  newField.value ? newField.value : ""
                                }
                              ></FVParagraph>
                            );
                          }
                          return <div></div>;
                        }
                      )}
                    </div>
                    {newSection.fields2 && (
                      <div id="fields1">
                        {newSection.fields2.map(
                          (newField: any, index: number) => {
                            if (newField.type === "textbox") {
                              return (
                                <FVTextField
                                  key={index}
                                  onChange={handleFieldChanged}
                                  onLocked={handleFieldLocked}
                                  id={newField.id}
                                  value={newField.value ? newField.value : ""}
                                  description={newField.description}
                                  title={newField.name}
                                  confirmed={newField.locked}
                                  formId={context.state.process?.id}
                                ></FVTextField>
                              );
                            }
                            if (newField.type === "dropdown") {
                              return (
                                <FVDropdown
                                  key={index}
                                  onChange={handleFieldChanged}
                                  onLocked={handleFieldLocked}
                                  description={newField.description}
                                  id={newField.id}
                                  properties={
                                    newField.options
                                      ? newField.options
                                      : ["No options"]
                                  }
                                  value={newField.value ? newField.value : ""}
                                  title={newField.name}
                                  confirmed={newField.locked}
                                ></FVDropdown>
                              );
                            }
                            if (newField.type === "paragraph") {
                              return (
                                <FVParagraph
                                  key={index}
                                  id={newField.id}
                                  paragraphText={
                                    newField.value ? newField.value : ""
                                  }
                                ></FVParagraph>
                              );
                            }
                            return <div></div>;
                          }
                        )}
                      </div>
                    )}
                  </FormSection>
                );
              }
            )}
          </div>
        </div>
      )}

      <UpdateFileModal
        show={showUploadDialog}
        handleClose={() => setShowUploadDialog(false)}
        handleCreate={(item: File) => {
          attachFile(
            item,
            context.state.process?.id!,
            context.dispatch,
            context.state
          );
          setShowUploadDialog(false);
        }}
      />

      <ViewHistoryModal
        show={showHistoryDialog}
        handleClose={() => setShowHistoryDialog(false)}
      />
    </FVContext.Provider>
  );
};
