/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { FilesContext } from "../context/FilesContext";

export default function FilesBreadcrumb() {
  const fileContext = useContext(FilesContext);

  const handleClick = (id: string, name: string) => {
    fileContext.dispatch({
      type: "SET_CURRENT_FOLDER",
      payload: {
        id: id,
        name: name,
      },
    });
  };

  return (
    <ol className="breadcrumb text-muted fs-6 fw-bold">
      {fileContext.state.folderTree.map((folder, index) => {
        return (
          <li className="breadcrumb-item pe-3" key={index} onClick={() => handleClick(folder.id, folder.name)}>
            <a href="#" className="pe-3">
              {folder.name}
            </a>
          </li>
        );
      })}
    </ol>
  );
}
