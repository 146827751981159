/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { TypeaheadMenuProps } from "react-bootstrap-typeahead/types/components/TypeaheadMenu";
import { FormUser } from "../../../../models/FormUser";

const filterBy = () => true;

interface PeoplePickerProps {
  onchange: (users: FormUser[]) => void;
  values?: FormUser[];
}

export default function PeoplePicker(props: PeoplePickerProps) {
  const [options, setOptions] = useState<FormUser[]>([]);
  const [selected, setSelected] = useState<FormUser[]>([]);
  const [loading, setLoading] = useState(false);


  const handleSearch = async (query: string) => {

    if (query.length > 2) {
      setLoading(true);
      const data = await fetch(`../api/users/${query}`);
      const users = await data.json();
  
      const newOptions = users.value.map((u: any) => {
        return {
          userId: u.id,
          userName: u.displayName,
          userEmail: u.mail,
        };
      });
      setOptions(newOptions);
      setLoading(false);
    }
  };

  const handleRemove = (id: string) => {
    const newSelected = selected.filter((s) => s.userId !== id);

    props.onchange(newSelected);

    setSelected(newSelected);
  };

  const handleChange = (newOption: any) => {

    //make sure the option is not already selected
    if (selected.find((s) => s.userId === newOption[0].userId)) {
      return;
    }

    //add new option to selected
    setSelected([...selected, ...(newOption as FormUser[])]);

    props.onchange([...selected, ...(newOption as FormUser[])]);

  };

  useEffect(() => {
    setSelected(props.values ?? []);
    return () => {
      setSelected([]);
    }
  }, [])
  
  return (
    <div>
      <Typeahead
        isLoading={loading}
        filterBy={filterBy}
        id="people-picker"
        labelKey="userName"
        options={options}
        selected={[]}
        onInputChange={handleSearch}
        placeholder="Person auswählen..."
        renderMenuItemChildren={(
          option: any,
          menuProps: TypeaheadMenuProps,
          idx: number
        ) => {
          return (
            <div
              key={idx}
              className="px-3 py-3"
            >{`${option.userName} (${option.userEmail})`}</div>
          );
        }}
        onChange={handleChange}
      />
      <div className="px-3 py-3 my-2 bg-secondary m-1">
        {selected.map((option, idx) => (
          <div className="badge bg-light-primary m-1">
            <span key={idx} className="fs-5 text-info">
              {option.userName}
            </span>
            <button
              className="btn btn-icon btn-active-light-success"
              style={{ height: "1rem" }}
              onClick={() => handleRemove(option.userId)}
            >
              <i className="lar la-times-circle"></i>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
