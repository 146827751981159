/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useContext } from "react";
import { KTSVG } from "../../../helpers";
import {
  NotificationContext,
} from "./context/NotificationContext";

export function HeaderNotificationsMenu() {
  const notificationContext = useContext( NotificationContext);

  return (
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded fw-bold menu-title-gray-800 menu-hover-bg menu-state-title-primary w-250px w-md-300px"
        data-kt-menu="true"
      >
        <div className="menu-item mx-3">
          <div className="menu-content fs-6 text-dark fw-bolder py-6">
            {notificationContext.state.notifications.length} neue Meldungen
          </div>
        </div>

        <div className="separator mb-3"></div>

        {notificationContext.state.notifications.map((notification) => (
          <div className="menu-item mx-3">
            <a href="#" className="menu-link px-4 py-3">
              <div className="symbol symbol-35px">
                <span className="symbol-label bg-light-info">
                  <KTSVG
                    className="svg-icon-3 svg-icon-info"
                    path="/media/icons/duotone/Home/Library.svg"
                  />
                </span>
              </div>

              <div className="ps-4">
                <span className="menu-title fw-bold mb-1">
                  {notification.content}
                </span>
                <span className="text-muted fw-bold d-block fs-7">
                  {
                    moment(notification.time).calendar()
                  }
                </span>
              </div>
            </a>
          </div>
        ))}
      </div>
  );
}
