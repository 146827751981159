import { Content, DataSet } from "../../../../models/dataset";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect } from "react";
import { SuperStoreService } from "../../../../services/SuperStoreService";

// #region State Type

export type DEContextState = {
  dataset: DataSet;
};

// #endregion

// #region Action Types
export type DEContextAction =
  | {
      type: "SET_DATASET";
      payload: DataSet;
    }
  | {
      type: "SAVE_DATASET";
    }
  | {
      type: "ADD_TERM";
      payload: {
        term: string;
      };
    }
  | {
      type: "DELETE_TERM";
      payload: {
        term: string;
      };
    }
  | {
      type: "UPDATE_TERMS";
      payload: Content[];
    }
  | {
      type: "SET_DATASET_NAME";
      payload: string;
    };
// #endregion

// #region Context Type
export type DEContextType = {
  state: DEContextState;
  dispatch: React.Dispatch<DEContextAction>;
};
// #endregion

// #region Initial State
export const initialDEContextState: DEContextState = {
  dataset: {
    id: uuidv4(),
    version: 1,
    entityType: "dataset",
    data: {
      name: "Neuer Datensatz",
      description: "",
      createdBy: "",
      id: uuidv4(),
      created: new Date(),
      version: 1,
      content: [],
    },
  },
};
// #endregion

// #region Context

export const DatasetEditorContext = React.createContext<DEContextType>({
  state: initialDEContextState,
  dispatch: () => null,
});

// #endregion

// #region Reducer

export const datasetEditorReducer = (
  state: DEContextState,
  action: DEContextAction
): DEContextState => {
  switch (action.type) {
    case "SET_DATASET":
      return setDataset(state, action.payload);
    case "SAVE_DATASET":
      return saveDataset(state);
    case "ADD_TERM":
      return addTerm(state, action.payload);
    case "DELETE_TERM":
      return deleteTerm(state, action.payload);
    case "UPDATE_TERMS":
      return updateTerms(state, action.payload);
    case "SET_DATASET_NAME":
      return updateDatasetName(state, action.payload);
    default:
      return state;
  }
};

// #endregion

// #region Reducer logic

// case "SET_DATESET":

const setDataset = (state: DEContextState, payload: DataSet) => {
  return {
    ...state,
    dataset: payload,
  };
};

const saveDataset = (state: DEContextState) => {

  const _client = SuperStoreService.getInstance();

  _client.upsertItem("dataset", state.dataset, state.dataset.id);

  return {
    ...state,
  };
};

const addTerm = (state: DEContextState, payload: { term: string }) => {
  return {
    ...state,
    dataset: {
      ...state.dataset,
      data: {
        ...state.dataset?.data,
        content: [
          ...state.dataset?.data.content!,
          {
            value: payload.term,
            id: uuidv4(),
          },
        ],
      },
    },
  };
};

const deleteTerm = (state: DEContextState, payload: { term: string }) => {
  return {
    ...state,
    dataset: {
      ...state.dataset,
      data: {
        ...state.dataset?.data,
        content: state.dataset?.data.content!.filter(
          (term) => term.value !== payload.term
        ),
      },
    },
  };
};

const updateTerms = (state: DEContextState, payload: Content[]) => {
  return {
    ...state,
    dataset: {
      ...state.dataset,
      data: {
        ...state.dataset?.data,
        content: payload.map((term) => {
          return {
            id: term.id,
            value: term.value,
          };
        }),
      },
    },
  };
};

const updateDatasetName = (state: DEContextState, name: string) => {
  return {
    ...state,
    dataset: {
      ...state.dataset,
      data: {
        ...state.dataset?.data,
        name: name,
      },
    },
  };
};

// #endregion

// #region Provider

export const useDEContext = (datasetId?: string | null) => {
  const [state, dispatch] = React.useReducer(
    datasetEditorReducer,
    initialDEContextState
  );

  useEffect(() => {
    if (datasetId) {
      fetchDataset(datasetId).then((dataset) => {
        if (dataset) dispatch({ type: "SET_DATASET", payload: dataset });
      });
    }
  }, [datasetId]);

  const fetchDataset = async (datasetId: string) => {
    const _client = SuperStoreService.getInstance();
    const dataset = await _client.getItem<DataSet>("dataset", datasetId);
    return dataset;
  };

  return { state, dispatch };
};

// #endregion
