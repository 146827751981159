import { SearchResult } from "../models/SearchResult";

export class SearchService {
  private static instance: SearchService = new SearchService();

  private constructor() {
    // Private constructor
  }

  public static getInstance(): SearchService {
    return SearchService.instance;
  }

  // we make this as a generic function so we can use it for any type of search

  public async search<T>(search: string): Promise<T[]> {
    const results = await fetch(`../api/search/${search}`);

    return (await results.json()) as T[];
  }

  public async searchProcesses(search: string): Promise<SearchResult[]> {
    return await this.search<SearchResult>(search);
  }
}
