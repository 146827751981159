export class UserService {
  private static instance: UserService = new UserService();

  private constructor() {
    // Private constructor
  }

  public static getInstance(): UserService {
    return UserService.instance;
  }

  public async getUserDetails(): Promise<any> {
    try {
      const url = `../api/User`;
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (err) {
      console.log("🔴 Error in UserService.getUserDetails: ", err);
      return null;
    }
  }
}