/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AppNotification } from "../../../../../models/AppNotification";
import { NotificationService } from "../../../../../services/NotificationService";

type NotificationContextState = {
  notifications: AppNotification[];
};

type NotificationContextActions = {
  type: "SET_NOTIFICATIONS";
  payload: AppNotification[];
};

export type NotificationContextType = {
  state: NotificationContextState;
  dispatch: React.Dispatch<NotificationContextActions>;
};

const initialState: NotificationContextType = {
  state: {
    notifications: [],
  },
  dispatch: () => {},
};

export const NotificationContext =
  React.createContext<NotificationContextType>(initialState);

NotificationContext.displayName = "NotificationContext";

export const useNotificationContext = () => {
  const [state, dispatch] = React.useReducer(
    (state: NotificationContextState, action: NotificationContextActions) => {
      switch (action.type) {
        case "SET_NOTIFICATIONS":
          return {
            ...state,
            notifications: action.payload,
          };
        default:
          return state;
      }
    },
    initialState.state
  );

  useEffect(() => {
    FetchNotifications(dispatch);

    RegisterForNotifications(dispatch, state);

    return () => {};
  }, []);

  return {
    state,
    dispatch,
  };
};

const RegisterForNotifications = async (
  dispatch: React.Dispatch<NotificationContextActions>,
  state: NotificationContextState
) => {
  const _notificationService = NotificationService.getInstance();

  _notificationService.addEventHandler((event) => {
    if (event) {
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: [event, ...state.notifications],
      });
    }
  });
};

const FetchNotifications = async (
  dispatch: React.Dispatch<NotificationContextActions>
) => {
  const _notificationService = NotificationService.getInstance();

  const notifications = await _notificationService.getNotifications();
  
  dispatch({
    type: "SET_NOTIFICATIONS",
    payload: notifications,
  });
};
