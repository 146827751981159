import { FormTemplate } from "../models/FormTemplate";
import { v4 as uuidv4 } from "uuid";
import { FormField, FormSection, Process } from "../models/Process";

export const createEmptyProcessTemplate = (): FormTemplate => {
  return {
    id: uuidv4(),
    entityType: "formtemplate",
    data: {
      name: "",
      description: "",
      version: 1,
      created: new Date(),
      createdBy: "",
      content: {
        sections: [],
      },
    },
  };
};

export const createEmptySection = (): FormSection => {
  return {
    id: uuidv4(),
    name: "Neue Sektion",
    fields1: [],
    fields2: [],
  };
};

export const createEmptyField = (fieldType: string): FormField => {
  const newField: FormField = {
    id: uuidv4(),
    type: fieldType,
    name: "",
    description: "",
    options: [],
    value: "",
    locked: false,
  };

  return newField;
};


export const formTemplateToProcess = (formTemplate: FormTemplate): Process => {

  const process: Process = {
    id: uuidv4(),
    entityType: "process",
    version: 1,
    data: {
      id: uuidv4(),
      created: "",
      template: formTemplate.data?.name ?? "",
      name: formTemplate.data!.name as string,
      description: formTemplate.data!.description as string,
      version: 1,
      createdBy: "",
      content: {
        sections: formTemplate.data!.content!.sections,
        history: [],
        activeSections: 0,
        completed: false,
        completedSections: 0
      },
    },
  };

  return process;
}