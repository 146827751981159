/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { getConfig, IThemeConfig, useTheme } from "../../../_start/layout/core";

import { FormViewerPage } from "./FormViewerPage";

const defaultPageConfig = getConfig();
const dashboardPageConfig: Partial<IThemeConfig> = {
  toolbar: {
    ...defaultPageConfig.toolbar,
    display: false,
  },
};

export function FormViewerWrapper() {
  const { setTheme } = useTheme();
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(dashboardPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return <FormViewerPage />;
}