export class NotificationService {
  private static _instace: NotificationService;
  private static client: WebSocket | null = null;
  public static state: "connected" | "disconnected" | "connecting" =
    "disconnected";
  private eventHandlers: ((event: any) => void)[] = [];
  private static ackId = 0;

  private constructor() {
    this.connect("none");
  }

  public addEventHandler(handler: (event: any) => void) {
    this.eventHandlers.push(handler);
  }

  private async connect(username: string) {
    const ws_resp = await fetch("../api/chat/login?userid=" + username);
    const ws_data = await ws_resp.json();

    NotificationService.client = new WebSocket(
      ws_data.url,
      "json.webpubsub.azure.v1"
    );

    NotificationService.client.onclose = (ev) => {
      console.log("💬 Disconnected from websocket");
      NotificationService.state = "disconnected";
    };

    NotificationService.client.onopen = () => {
      console.log("💬 Connected to websocket");
      NotificationService.state = "connected";
      this.joinGroup();
    };

    NotificationService.client.onmessage = (event) => {
      console.log("💬 Got event from websocket: " + event.data);

      if(event.data)
        this.eventHandlers.forEach((handler) => handler(JSON.parse(event.data).data));
    };
  }

  public static getInstance() {
    if (!this._instace) {
      this._instace = new NotificationService();
    }

    return this._instace;
  }

  public async sendEvent(notificationText: string) {
    await fetch(
      `../api/Notification?messageContent=${notificationText}&messageType=event`,
      { method: "POST" }
    );
  }

  public async getNotifications() {
    const resp = await fetch("../api/Notification");
    const data = await resp.json();
    return data;
  }

  public async joinGroup() {
    if (
      NotificationService.state === "connected" &&
      NotificationService.client
    ) {
      NotificationService.client.send(
        JSON.stringify({
          type: "joinGroup",
          group: "notifications",
          ackId: NotificationService.ackId++,
        })
      );
    }
  }
}
