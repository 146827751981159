/* eslint-disable jsx-a11y/anchor-is-valid */
import Avatar from "boring-avatars";
import React from "react";
import { useAppContext } from "../../../../../../app/context/AppContext";
import { ChatMessage } from "../../../../../../models/ChatMessage";

interface ChatMessageProps {
  msg: ChatMessage;
  index: number;
}

export default function ChatMessageElement(props: ChatMessageProps) {
  const appContext = useAppContext();

  const isFromMe = props.msg.username === appContext.state.loggedInUser?.userDetails;


  return (
    <div
      key={`msg${props.index}`}
      className={`d-flex flex-column mb-5 align-items-${
        isFromMe ? "end" : "start"
      }`}
    >
      {isFromMe && (
        <>
          {/* begin::Message Out*/}
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column text-end">
              <a
                href="#"
                className="text-gray-600 text-hover-primary fw-bolder"
              >
                {props.msg.username}
              </a>
              <span className="text-muted fw-bold fs-7">
                {new Date().toLocaleDateString()}
              </span>
            </div>
            {/* begin::Symbol*/}
            <div className="symbol symbol-40px flex-shrink-0 ms-4">
              <span className="symbol-label bg-light">
                <Avatar size={30} variant="pixel" name={props.msg.username} />
              </span>
            </div>
            {/* end::Symbol*/}
          </div>
          <div className="rounded mt-2 p-5 bg-light-success text-gray-600 text-end mw-400px">
            {props.msg.content}
          </div>
          {/* end::Message Out*/}
        </>
      )}
      {!isFromMe && (
        <>
          {/* begin::Message*/}

          <div className="d-flex align-items-center">
            {/* begin::Symbol*/}
            <div className="symbol symbol-40px flex-shrink-0 me-4">
              <span className="symbol-label bg-light">
                <Avatar size={30} variant="pixel" name={props.msg.username} />
              </span>
            </div>
            {/* end::Symbol*/}
            <div className="d-flex flex-column">
              <a
                href="#"
                className="text-gray-600 text-hover-primary fw-bolder"
              >
                {props.msg.username}
              </a>
              <span className="text-muted fw-bold fs-7">
                {new Date().toLocaleDateString()}
              </span>
            </div>
          </div>
          <div className="rounded mt-2 p-5 text-gray-600  mw-400px bg-light-primary text-start">
            {props.msg.content}
          </div>
          {/* end::Message In*/}
        </>
      )}
    </div>
  );
}
