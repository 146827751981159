import React from "react";
import { useNavigate } from "react-router-dom";
import { SearchResult } from "../../../../models/SearchResult";

import "./SearchResultRow.css";

interface Props {
  searchResult: SearchResult;
  handleClick: () => void;
}

export default function SearchResultRow(props: Props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/formviewer/${props.searchResult.id}`);
    props.handleClick();
  };

  return (
    <div className="searchResult">
      <div
        className="d-flex flex-row align-items-center p-3 justify-content-between"
        onClick={handleClick}
      >
        <div className="d-flex flex-column">
          <div className="fw-bolder fs-6">{props.searchResult.name}</div>
          <div className="fw-bold fs-8">{props.searchResult.template}</div>
        </div>
        <div>
          <span className="badge badge-light-primary">
            {props.searchResult.createdBy}
          </span>
        </div>
        <div>
          <span className="badge badge-light-primary">
            {new Date(props.searchResult.created).toLocaleDateString()}
          </span>
        </div>
      </div>
    </div>
  );
}
