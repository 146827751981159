/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { KTSVG } from "../../../helpers";
import { TWPContext } from "../../widgets/tables/context/TWPContext";

export default function ProcessFilterDropdown() {
  const context = useContext(TWPContext);

  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
      >
        <KTSVG
          path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
          className="svg-icon-1"
        />
      </button>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">
            Filtrieren & Sortieren
          </div>
        </div>

        <div className="separator mb-3 opacity-75"></div>

        <div className="menu-item px-3">
          <div className="d-flex flex-row align-items-center gap-3">
            <i
              className="las la-check"
              hidden={
                context.state.paging.filter !== undefined ||
                context.state.paging.filterBy !== undefined
              }
            ></i>
            <a
              href="#"
              className="menu-link px-3"
              onClick={() => {
                context.dispatch({
                  type: "SET_PAGE",
                  payload: {
                    take: context.state.itemsPerPage,
                    skip: 0,
                    filterBy: undefined,
                    filter: undefined,
                    order: context.state.paging.order,
                    orderBy: context.state.paging.orderBy,
                  },
                });
              }}
            >
              Alle Prozesse
            </a>
          </div>
        </div>

        <div className="menu-item px-3">
          <div className="d-flex flex-row align-items-center gap-3">
            <i
              className="las la-check"
              hidden={
                context.state.paging.filter !== "true" ||
                context.state.paging.filterBy !== "data.content.completed"
              }
            ></i>
            <a
              href="#"
              className="menu-link px-3"
              onClick={() => {
                context.dispatch({
                  type: "SET_PAGE",
                  payload: {
                    take: context.state.itemsPerPage,
                    skip: 0,
                    filterBy: "data.content.completed",
                    filter: "true",
                    order: context.state.paging.order,
                    orderBy: context.state.paging.orderBy,
                  },
                });
              }}
            >
              Fertige Prozesse
            </a>
          </div>
        </div>

        <div className="menu-item px-3">
          <div className="d-flex flex-row align-items-center gap-3">
            <i
              className="las la-check"
              hidden={
                context.state.paging.filter !== "false" ||
                context.state.paging.filterBy !== "data.content.completed"
              }
            ></i>
            <a
              href="#"
              className="menu-link px-3"
              onClick={() => {
                context.dispatch({
                  type: "SET_PAGE",
                  payload: {
                    take: context.state.itemsPerPage,
                    skip: 0,
                    filterBy: "data.content.completed",
                    filter: "false",
                    order: context.state.paging.order,
                    orderBy: context.state.paging.orderBy,
                  },
                });
              }}
            >
              Laufende Prozesse
            </a>
          </div>
        </div>

        <div className="separator mt-3 opacity-75"></div>

        <div
          className="menu-item px-3"
          data-kt-menu-trigger="hover"
          data-kt-menu-placement="right-start"
          data-kt-menu-flip="left-start, top"
        >
          <a href="#" className="menu-link px-3">
            <span className="menu-title">Sortieren</span>
            <span className="menu-arrow"></span>
          </a>

          <div className="menu-sub menu-sub-dropdown w-200px py-4">
            <div className="menu-item px-3">
              <a
                href="#"
                className="menu-link px-3"
                onClick={() => {
                  context.dispatch({
                    type: "SET_PAGE",
                    payload: {
                      take: context.state.itemsPerPage,
                      skip: 0,
                      orderBy: "data.created",
                      order: "desc",
                      filter: context.state.paging.filter,
                      filterBy: context.state.paging.filterBy,
                    },
                  });
                }}
              >
                <div className="d-flex align-items-center flex-row gap-3">
                  <i
                    className="las la-check"
                    hidden={
                      context.state.paging.order !== "desc" ||
                      context.state.paging.orderBy !== "data.created"
                    }
                  ></i>
                  Datum absteigend
                </div>
              </a>
            </div>

            <div className="menu-item px-3">
              <a
                href="#"
                className="menu-link px-3"
                onClick={() => {
                  context.dispatch({
                    type: "SET_PAGE",
                    payload: {
                      take: context.state.itemsPerPage,
                      skip: 0,
                      orderBy: "data.created",
                      order: "asc",
                      filter: context.state.paging.filter,
                      filterBy: context.state.paging.filterBy,
                    },
                  });
                }}
              >
                <div className="d-flex align-items-center flex-row gap-3">
                  <i
                    className="las la-check"
                    hidden={
                      context.state.paging.order !== "asc" ||
                      context.state.paging.orderBy !== "data.created"
                    }
                  ></i>
                  Datum aufsteigend
                </div>
              </a>
            </div>
          </div>
        </div>

        <div
          className="menu-item px-3"
          data-kt-menu-trigger="hover"
          data-kt-menu-placement="right-start"
          data-kt-menu-flip="left-start, top"
        >
          <a href="#" className="menu-link px-3">
            <span className="menu-title">Prozess Typ</span>
            <span className="menu-arrow"></span>
          </a>

          <div className="menu-sub menu-sub-dropdown w-200px py-4">
            <div className="menu-item px-3">
              <a
                href="#"
                className="menu-link px-3"
                onClick={() => {
                  context.dispatch({
                    type: "SET_PAGE",
                    payload: {
                      take: context.state.itemsPerPage,
                      skip: 0,
                      orderBy: context.state.paging.orderBy,
                      order: context.state.paging.order,
                      filter: undefined,
                      filterBy: undefined,
                    },
                  });
                }}
              >
                <div className="d-flex align-items-center flex-row gap-3">
                  <i
                    className="las la-check"
                    hidden={context.state.paging.filterBy === "data.template"}
                  ></i>
                  Alle Templates
                </div>
              </a>
            </div>
            {context.state.templates.map((template, index) => {
              return (
                <div className="menu-item px-3" key={'processFilterItem' + index}>
                  <a
                    href="#"
                    className="menu-link px-3"
                    onClick={() => {
                      context.dispatch({
                        type: "SET_PAGE",
                        payload: {
                          take: context.state.itemsPerPage,
                          skip: 0,
                          orderBy: context.state.paging.orderBy,
                          order: context.state.paging.order,
                          filter: template.data?.name,
                          filterBy: "data.template",
                        },
                      });
                    }}
                  >
                    <div className="d-flex align-items-center flex-row gap-3">
                      <i
                        className="las la-check"
                        hidden={
                          context.state.paging.filter !== template.data?.name ||
                          context.state.paging.filterBy !== "data.template"
                        }
                      ></i>
                      {template.data?.name}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
