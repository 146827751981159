/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FilesWidget from "../../../../_start/partials/widgets/files/FilesWidget";

export const FilesDashboardPage: React.FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div className="col-xl-12">
        <FilesWidget />
      </div>
    </>
  );
};
