/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { DataSet } from "../../../../../models/dataset";
import { Paging } from "../../../../../models/paging";
import { SuperStoreService } from "../../../../../services/SuperStoreService";
import { calculatePageSize } from "../../../../../utils/listUtils";

export type TWDContextState = {
  datasets: DataSet[];
  paging: Paging;
  loading: boolean;
  error: string;
  pageNumber: number;
  totalCount: number;
  itemsPerPage: number;
};

export type TWDContextAction = {
  type:
    | "PAGE_FORWARD"
    | "PAGE_BACKWARD"
    | "SET_PAGE"
    | "SET_ITEMS"
    | "SET_ERROR"
    | "SET_LOADING"
    | "SET_TOTAL_COUNT";
  payload?: DataSet[] | Paging | string | boolean | number;
};

export type TWDContextType = {
  state: TWDContextState;
  dispatch: React.Dispatch<TWDContextAction>;
};

export const TWDContext = React.createContext<TWDContextType>({
  state: {
    datasets: [],
    paging: {
      take: 10,
      skip: 0,
    },
    loading: false,
    error: "",
    pageNumber: 0,
    totalCount: 0,
    itemsPerPage: 10,
  },
  dispatch: () => {},
});

const initialState: TWDContextState = {
  datasets: [],
  paging: {
    take: 10,
    skip: 0,
  },
  loading: false,
  error: "",
  pageNumber: 0,
  totalCount: 0,
  itemsPerPage: calculatePageSize(),
};

export const useTWDContext = () => {
  const [state, dispatch] = React.useReducer(
    (state: TWDContextState, action: TWDContextAction) => {
      switch (action.type) {
        case "PAGE_FORWARD":
          if (state.paging.skip + state.paging.take < state.totalCount) {
            return {
              ...state,
              pageNumber: state.pageNumber + 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip + state.paging.take,
              },
            };
          } else return state;
        case "PAGE_BACKWARD":
          if (state.paging.skip - state.paging.take >= 0) {
            return {
              ...state,
              pageNumber: state.pageNumber - 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip - state.paging.take,
              },
            };
          } else return state;
        case "SET_PAGE":
          return {
            ...state,
            paging: {
              ...state.paging,
              skip: 0,
            },
          };
        case "SET_ITEMS":
          return {
            ...state,
            datasets: action.payload as DataSet[],
          };
        case "SET_ERROR":
          return {
            ...state,
            error: action.payload as string,
          };
        case "SET_LOADING":
          return {
            ...state,
            loading: action.payload as boolean,
          };
        case "SET_TOTAL_COUNT":
          return {
            ...state,
            totalCount: action.payload as number,
          };
        default:
          return state;
      }
    },
    initialState
  );

  React.useEffect(() => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });

    Promise.all([fetchItems(dispatch, state), fetchTotalCount(dispatch)])
      .then(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        dispatch({
          type: "SET_ERROR",
          payload: "Error fetching data",
        });
      });

    return () => {};
  }, [state.paging]);

  return {
    state,
    dispatch,
  };
};

const fetchItems = async (
  dispatch: React.Dispatch<TWDContextAction>,
  state: TWDContextState
) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _items = await _client.getItems<DataSet>(state.paging, "dataset");
    dispatch({
      type: "SET_ITEMS",
      payload: _items,
    });
  } catch (error) {}
};

const fetchTotalCount = async (dispatch: React.Dispatch<TWDContextAction>) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _totalCount = await _client.getItemsCount("dataset");
    dispatch({
      type: "SET_TOTAL_COUNT",
      payload: _totalCount,
    });
  } catch (error) {}
};

export const deleteDataset = async (
  dispatch: React.Dispatch<TWDContextAction>,
  state: TWDContextState,
  id: string
) => {
  try {    
    const _client = SuperStoreService.getInstance();
    await _client.deleteItem("dataset", id);
    await fetchItems(dispatch, state);
    await fetchTotalCount(dispatch);
  } catch (error) {
    console.log(error);
  }
};
