/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ProcessFilterDropdown from "../../content/dropdown/ProcessFilterDropdown";
import { deleteProcess, TWPContext, useTWPContext } from "./context/TWPContext";
import DeleteConfirmationModal from "./modals/DeleteConfirmationModal";
import NewProcessModal from "./modals/NewProcessModal";
import ProcessesRow from "./rows/ProcessesRow";

type Props = {
  className: string;
  innerPadding?: string;
};

export default function TablesWidgetProcesses(props: Props) {
  const context = useTWPContext();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showNewProcessModal, setShowNewProcessModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDelete = () => {
    deleteProcess(context.dispatch, context.state, deleteId);
    setShowDeleteConfirmation(false);
  };

  return (
    <TWPContext.Provider value={context}>
      <div className={`card ${props.className}`}>
        {/* <!--begin::Header--> */}
        <div className={`card-header border-0 pt-5 ${props.innerPadding}`}>
          <h3 className="card-title align-items-start flex-column">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <span className="card-label fw-bolder text-dark fs-3">
                Prozesse
              </span>
              <span
                hidden={context.state.loading === false}
                className="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </div>
            <span className="text-muted mt-2 fw-bold fs-6">
              {context.state.totalCount} Prozesse
            </span>
          </h3>
          <div className="card-toolbar">
            <button
              className="btn btn-sm btn-success mx-3"
              onClick={() => setShowNewProcessModal(true)}
            >
              Neuen Prozess erstellen
            </button>
            <ul className="nav nav-pills nav-pills-sm nav-light">
              <li className="nav-item d-flex gap-2">
                <button
                  disabled={false}
                  className="btn btn-sm btn-icon btn-light"
                  onClick={() => context.dispatch({ type: "PAGE_BACKWARD" })}
                >
                  <i className="las la-angle-left fs-2"></i>
                </button>
                <div
                  className="d-grid align-center text-muted fs-8"
                  style={{ alignContent: "center" }}
                >
                  {context.state.pageNumber} /{" "}
                  {Math.floor(
                    context.state.totalCount / context.state.itemsPerPage
                  )}
                </div>
                <button
                  disabled={false}
                  className="btn btn-sm btn-icon btn-light"
                  onClick={() => context.dispatch({ type: "PAGE_FORWARD" })}
                >
                  <i className="las la-angle-right fs-2"></i>
                </button>
              </li>
            </ul>
            <div style={{ marginLeft: "1rem" }}>
              <ProcessFilterDropdown />
            </div>
          </div>
        </div>
        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className="tab-content mt-5" id="myTabTables1">
            {/* <!--begin::Tap pane--> */}
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_1_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_1_1"
            >
              {/* <!--begin::Table--> */}
              <div className="table-responsive">
                <table className="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th className="p-0 min-w-150px"></th>
                      <th className="p-0 min-w-100px"></th>
                      <th className="p-0 min-w-100px"></th>
                      <th className="p-0 min-w-100px"></th>
                      <th className="p-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state.processes.map((process, index) => {
                      return (
                        <ProcessesRow
                          key={index}
                          processTitle={process.data.name}
                          processType={process.data.template}
                          processOwner={process.data.createdBy}
                          processDate={new Date(
                            process.data.created
                          ).toLocaleDateString()}
                          processProgress={
                            (process.data.content.completedSections /
                              (process.data.content.completedSections +
                                process.data.content.activeSections)) *
                            100
                          }
                          processId={process.id}
                          handleDelete={() => {
                            setShowDeleteConfirmation(true);
                            setDeleteId(process.id);
                          }}
                          processLocked={process.locked}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!--end::Table--> */}
            </div>
            {/* <!--end::Tap pane--> */}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        handleDelete={() => handleDelete()}
        message="Sind sie sicher dass dieser Prozess geloescht werden soll?"
        show={showDeleteConfirmation}
        handleClose={() => setShowDeleteConfirmation(false)}
      />
      <NewProcessModal
        show={showNewProcessModal}
        handleClose={() => setShowNewProcessModal(false)}
      />
    </TWPContext.Provider>
  );
}
