export class StatisticsService {
  private static _instance: StatisticsService;

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public async ProcessesByTemplate() {
    const response = await fetch(
      `../api/Statistics/ProcessesByTemplate`,
    );
    return response.json();
  }

  public async ProcessesByCreator() {
    const response = await fetch(
      `../api/Statistics/ProcessesByCreator`,
    );
    return response.json();
  }

  public async EntityByType() {
    const response = await fetch(
      `../api/Statistics/EntityByType`,
    );
    return response.json();
  }

  public async GetLast30Days() {
    const response = await fetch(
      `../api/Statistics/ProcessesLast30Days`,
    );
    return response.json();
  }


}