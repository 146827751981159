/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { ReactSortable } from "react-sortablejs";
import { v4 as uuidv4 } from "uuid";
import { FBContext } from "../context/FormBuilderContext";

export interface TermSetPickerProps {
  options: string[];
  handleOptionsChange: (options: string[]) => void;
}

export default function TermsetPicker(props: TermSetPickerProps) {
  const formBuilderContext = useContext(FBContext);

  const text = useRef<HTMLInputElement>(null);
  const termSet = useRef<HTMLSelectElement>(null);

  const [showSettings, setShowSettings] = useState(false);

  const addTerm = () => {
    if (text.current?.value) {
      props.handleOptionsChange([...props.options, text.current.value]);
      text.current.value = "";
    }
  };
  const deleteTerm = (id: string) => {
    props.handleOptionsChange(props.options.filter((option) => option !== id));
  };

  const handleSort = (options: { value: string; id: string }[]) => {
    props.handleOptionsChange(options.map((option) => option.value));
  };

  const handleTermSetChange = () => {
    const termSetId = termSet.current?.value;

    const newOptions =
      formBuilderContext.state.datasets.find(
        (dataset) => dataset.data.name === termSetId
      )?.data.content ?? [];

    props.handleOptionsChange(newOptions.map((option: any) => option.value));
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "0.3rem",
          }}
        >
          <input
            ref={text}
            type="text"
            className="form-control"
            placeholder="Enter value"
          />
          <button className="btn btn-sm btn-icon" onClick={() => addTerm()}>
            <i className="las la-plus fs-3"></i>
          </button>
          <button
            className="btn btn-sm btn-icon"
            onClick={() => setShowSettings(true)}
          >
            <i className="las la-book fs-3"></i>
          </button>
        </div>
        <div>
          <ReactSortable
            list={props.options.map((item) => {
              return { value: item, id: uuidv4() };
            })}
            setList={handleSort}
            animation={150}
            handle=".dragHandleControl"
            style={{maxHeight: "14rem", overflowY:"scroll"}}
          >
            {props.options.map((option) => (
              <div
                className="bg-light-info text-info my-1 px-3 py-2"
                key={option}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <div className="p-1 dragHandleControl">
                      <button className="btn btn-sm btn-icon">
                        <i className="las la-bars fs-3"></i>
                      </button>
                    </div>
                    <div>{option}</div>
                  </div>
                  <div>
                    <button
                      className="btn btn-sm btn-icon"
                      onClick={() => deleteTerm(option)}
                    >
                      <i className="las la-trash fs-3"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>
      </div>
      <Modal
        onHide={() => {
          setShowSettings(false);
        }}
        show={showSettings}
        centered
      >
        <Modal.Header>
          <Modal.Title>An Datensatz binden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-select"
            aria-label="Select example"
            ref={termSet}
          >
            <option>Keinen Datensatz benutzen</option>
            {formBuilderContext.state.datasets.map((dataset) => {
              return <option key={dataset.id}>{dataset.data.name}</option>;
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleTermSetChange()}>
            Binden
          </Button>
          <Button variant="secondary" onClick={() => setShowSettings(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
