/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

type ProcessRowProps = {
  processTitle: string;
  processType: string;
  processOwner: string;
  processDate: string;
  processProgress: number;
  processId: string;
  handleDelete: (processId: string) => void;
  handleRestore: (processId: string) => void;
};

export default function RecycleBinRow(props: ProcessRowProps) {
  const navigate = useNavigate();
  return (
    <tr>
      <td className="ps-0">
        <a
          className="text-gray-800 fw-bolder text-hover-primary fs-6"
          onClick={() => navigate(`/formviewer/${props.processId}`)}
        >
          {props.processTitle}
        </a>
        <span className="text-muted fw-bold d-block mt-1">
          {props.processType}
        </span>
      </td>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          <span className="text-muted">{props.processOwner}</span>
        </a>
      </td>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          {props.processDate}
        </a>
      </td>
      <td>
        <div className="d-flex gap-3">
          <span className="badge badge-light">Process</span>
          <span className="badge badge-dark">Deleted</span>
        </div>
      </td>
      <td className="text-end d-flex align-items-center justify-content-end gap-3 mt-2">
        <a
          onClick={() => {
            props.handleRestore(props.processId);
          }}
          href="#"
          className="btn btn-icon btn-light-primary btn-sm"
        >
          <i className="las la-recycle fs-2"></i>
        </a>
        <a
          onClick={() => {
            props.handleDelete(props.processId);
          }}
          href="#"
          className="btn btn-icon btn-light-primary btn-sm"
        >
          <i className="las la-trash fs-2"></i>
        </a>
      </td>
    </tr>
  );
}
