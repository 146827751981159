/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { v4 as uuidv4 } from "uuid";

import { FormTemplate } from "../../../../../models/FormTemplate";
import { Paging } from "../../../../../models/paging";
import { Process } from "../../../../../models/Process";
import { NotificationService } from "../../../../../services/NotificationService";
import { SuperStoreService } from "../../../../../services/SuperStoreService";
import { calculatePageSize } from "../../../../../utils/listUtils";
import { formTemplateToProcess } from "../../../../../utils/templateUtils";

export type TWPContextState = {
  processes: Process[];
  templates: FormTemplate[];
  paging: Paging;
  loading: boolean;
  error: string;
  pageNumber: number;
  totalCount: number;
  itemsPerPage: number;
};

export type TWPContextAction = {
  type:
    | "PAGE_FORWARD"
    | "PAGE_BACKWARD"
    | "SET_PAGE"
    | "SET_PROCESSES"
    | "SET_ERROR"
    | "SET_LOADING"
    | "SET_TOTAL_COUNT"
    | "SET_TEMPLATES";
  payload?:
    | Process[]
    | Paging
    | string
    | boolean
    | number
    | FormTemplate[]
    | { name: string; templateId: string };
};

export type TWPContextType = {
  state: TWPContextState;
  dispatch: React.Dispatch<TWPContextAction>;
};

export const TWPContext = React.createContext<TWPContextType>({
  state: {
    processes: [],
    templates: [],
    paging: {
      take: calculatePageSize(),
      skip: 0,
    },
    loading: false,
    error: "",
    pageNumber: 0,
    totalCount: 0,
    itemsPerPage: calculatePageSize(),
  },
  dispatch: () => {},
});

TWPContext.displayName = "TWPContext";

const initialState: TWPContextState = {
  processes: [],
  templates: [],
  paging: {
    take: calculatePageSize(),
    skip: 0,
    order: "desc",
    orderBy: "data.created",
    filterBy: "data.content.completed",
    filter: "false",
  },
  loading: false,
  error: "",
  pageNumber: 0,
  totalCount: 0,
  itemsPerPage: calculatePageSize(),
};

export const useTWPContext = () => {

  const [state, dispatch] = React.useReducer(
    (state: TWPContextState, action: TWPContextAction) => {
      switch (action.type) {
        case "PAGE_FORWARD":
          if (state.paging.skip + state.paging.take < state.totalCount) {
            return {
              ...state,
              pageNumber: state.pageNumber + 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip + state.paging.take,
              },
            };
          } else return state;
        case "PAGE_BACKWARD":
          if (state.paging.skip - state.paging.take >= 0) {
            return {
              ...state,
              pageNumber: state.pageNumber - 1,
              paging: {
                ...state.paging,
                skip: state.paging.skip - state.paging.take,
              },
            };
          } else return state;
        case "SET_PAGE":
          console.log(action.payload);
          return {
            ...state,
            paging: action.payload as Paging,
          };
        case "SET_PROCESSES":
          return {
            ...state,
            processes: action.payload as Process[],
          };
        case "SET_ERROR":
          return {
            ...state,
            error: action.payload as string,
          };
        case "SET_LOADING":
          return {
            ...state,
            loading: action.payload as boolean,
          };
        case "SET_TOTAL_COUNT":
          return {
            ...state,
            totalCount: action.payload as number,
          };

        case "SET_TEMPLATES":
          return {
            ...state,
            templates: action.payload as FormTemplate[],
          };
        default:
          return state;
      }
    },
    initialState
  );

  React.useEffect(() => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });

    Promise.all([
      fetchProcesses(dispatch, state),
      fetchTotalCount(dispatch, state),
      fetchTemplates(dispatch),
    ])
      .then(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_LOADING",
          payload: false,
        });
        dispatch({
          type: "SET_ERROR",
          payload: "Error fetching data",
        });
      });

    return () => {};
  }, [state.paging]);

  return {
    state,
    dispatch,
  };
};

const fetchProcesses = async (
  dispatch: React.Dispatch<TWPContextAction>,
  state: TWPContextState
) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _processes = await _client.getItems<Process>(state.paging, "process");
    dispatch({
      type: "SET_PROCESSES",
      payload: _processes,
    });
  } catch (error) {}
};

const fetchTotalCount = async (
  dispatch: React.Dispatch<TWPContextAction>,
  state: TWPContextState
) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _totalCount = await _client.getItemsCount("process", state.paging);
    dispatch({
      type: "SET_TOTAL_COUNT",
      payload: _totalCount,
    });
  } catch (error) {}
};

const fetchTemplates = async (dispatch: React.Dispatch<TWPContextAction>) => {
  try {
    const _client = SuperStoreService.getInstance();
    const _templates = await _client.getItems<FormTemplate>(
      { take: 1000, skip: 0 },
      "formtemplate"
    );
    dispatch({
      type: "SET_TEMPLATES",
      payload: _templates,
    });
  } catch (error) {}
};

export const createProcess = async (
  dispatch: React.Dispatch<TWPContextAction>,
  state: TWPContextState,
  payload: {
    name: string;
    templateId: string;
  }
): Promise<Process | null> => {
  console.log("payload:", payload);

  try {
    const _client = SuperStoreService.getInstance();
    const _template = await _client.getItem<FormTemplate>(
      "formtemplate",
      payload.templateId
    );

    const _process = formTemplateToProcess(_template!);

    if (_process && _process.data) {
      _process.data.name = payload.name;
      _process.id = uuidv4();
      _process.entityType = "process";
      await _client.upsertItem("process", _process, _process.id);

      await fetchProcesses(dispatch, state);
      await fetchTotalCount(dispatch, state);

      sendNewProcessNotification(_process);

      return _process;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
  return null;
};

export const sendNewProcessNotification = async (process: Process) => {
  try {

    const _notiticationService = NotificationService.getInstance();

    _notiticationService.sendEvent(`Ein neuer Prozess wurde gestartet: ${process.data.name}`);

  } catch (error) {
    console.log(error);
  }


}

export const deleteProcess = async (
  dispatch: React.Dispatch<TWPContextAction>,
  state: TWPContextState,
  id: string
) => {
  try {
    const _client = SuperStoreService.getInstance();
    await _client.deleteItem("process", id);
    await fetchProcesses(dispatch, state);
    await fetchTotalCount(dispatch, state);
  } catch (error) {
    console.log(error);
  }
};
