import { Process } from "../../../../../models/Process";
import { SuperStoreService } from "../../../../../services/SuperStoreService";

export const startDashboardLoader = async () : Promise<{processes: Process[]}> => {
  const _client = SuperStoreService.getInstance();
  const processes = await _client.getItems<Process>(
    { take: 10, skip: 0 },
    "process"
  );
  return { processes };
};
