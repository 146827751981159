/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TablesWidgetRecycleBin from "../../../../_start/partials/widgets/tables/TablesWidgetRecycleBin";

export const RecycleDashboardPage: React.FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div className="col-xl-12">
        <TablesWidgetRecycleBin className="card-stretch mb-5 mb-xxl-8" />
      </div>
    </>
  );
};
