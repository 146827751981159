/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { ChangeNotification } from "../../../../models/ChangeNotification";
import { ItemHistory, Process } from "../../../../models/Process";
import { AuthoringService } from "../../../../services/AuthoringService";
import { FileService } from "../../../../services/FileService";
import { NotificationService } from "../../../../services/NotificationService";
import { SuperStoreService } from "../../../../services/SuperStoreService";
import { AppContext } from "../../../context/AppContext";
import { createLockValue } from "../../../../utils/ lockUtils";
import { act } from "react-dom/test-utils";

export type FVContextState = {
  history: ItemHistory[];
  process: Process | null;
  error: string | null;
  changeNotifications: ChangeNotification[];
};

export type FVContextAction =
  | {
      type: "SET_PROCESS";
      payload: Process | null;
    }
  | {
      type: "SAVE_PROCESS";
    }
  | {
      type: "SET_ERROR";
      payload: string;
    }
  | {
      type: "UPDATE_PROCESS";
      payload: Process;
    }
  | {
      type: "UPDATE_FIELD_VALUE";
      payload: {
        fieldId: string;
        value: string;
        processId: string;
      };
    }
  | {
      type: "UPDATE_FIELD_VALUE_SYSTEM";
      payload: {
        fieldId: string;
        value: string;
      };
    }
  | {
      type: "UPDATE_LOCK";
      payload: {
        id: string;
        value: boolean;
      };
    }
  | {
      type: "SET_HISTORY";
      payload: ItemHistory[];
    }
  | {
      type: "REMOVE_FILE";
      payload: {
        fileName: string;
        parentId: string;
      };
    }
  | {
      type: "ADD_CHANGE_NOTIFICATION";
      payload: ChangeNotification;
    }
  | {
      type: "REMOVE_CHANGE_NOTIFICATION";
      payload: ChangeNotification;
    }
  | {
      type: "LOCK_PROCESS";
    }
  | {
      type: "UNLOCK_PROCESS";
    };

export type FVContextType = {
  state: FVContextState;
  dispatch: React.Dispatch<FVContextAction>;
};

const initialState: FVContextState = {
  history: [],
  process: null,
  error: null,
  changeNotifications: [],
};

export const FVContext = React.createContext<FVContextType>({
  state: initialState,
  dispatch: () => {},
});

FVContext.displayName = "FVContext";

export const useFVContext = (processId: string) => {
  const appContext = useContext(AppContext);

  const [state, dispatch] = React.useReducer(
    (state: FVContextState, action: FVContextAction) => {
      switch (action.type) {
        case "SET_PROCESS":
          return {
            ...state,
            process: action.payload,
          };
        case "SAVE_PROCESS": {
          try {
            if (state.process) {
              const _client = SuperStoreService.getInstance();
              _client.upsertItem<Process>(
                "process",
                state.process,
                state.process.id
              );
              const _notificationService = NotificationService.getInstance();
              _notificationService.sendEvent(
                `Prozess "${state.process.data.name}" wurde bearbeitet`
              );
            }
          } catch (err) {
            dispatch({ type: "SET_ERROR", payload: "Error while saving item" });
          }
          return state;
        }
        case "SET_ERROR":
          return {
            ...state,
            error: action.payload,
          };
        case "UPDATE_PROCESS": {
          return {
            ...state,
            process: action.payload,
          };
        }
        case "UPDATE_LOCK": {
          if (state.process) {
            const _process = { ...state.process };

            _process.data.content.sections.forEach((section) => {
              section.fields1.forEach((field) => {
                if (field.id === action.payload.id) {
                  field.locked = action.payload.value;
                }
              });
              if (section.fields2)
                section.fields2.forEach((field) => {
                  if (field.id === action.payload.id) {
                    field.locked = action.payload.value;
                  }
                });
            });

            return {
              ...state,
              process: _process,
            };
          }
          return state;
        }
        case "UPDATE_FIELD_VALUE": {
          if (state.process) {
            const _process = { ...state.process };

            _process.data.content.sections.forEach((section) => {
              section.fields1.forEach((field) => {
                if (field.id === action.payload.fieldId) {
                  field.value = action.payload.value;
                }
              });
              if (section.fields2)
                section.fields2.forEach((field) => {
                  if (field.id === action.payload.fieldId) {
                    field.value = action.payload.value;
                  }
                });
            });

            AuthoringService.sendEvent(
              action.payload.fieldId,
              action.payload.value,
              action.payload.processId
            );

            return {
              ...state,
              process: _process,
            };
          }
          return state;
        }

        //update without sending event
        case "UPDATE_FIELD_VALUE_SYSTEM": {
          if (state.process) {
            const _process = { ...state.process };

            _process.data.content.sections.forEach((section) => {
              section.fields1.forEach((field) => {
                if (field.id === action.payload.fieldId) {
                  field.value = action.payload.value;
                }
              });
              if (section.fields2)
                section.fields2.forEach((field) => {
                  if (field.id === action.payload.fieldId) {
                    field.value = action.payload.value;
                  }
                });
            });

            return {
              ...state,
              process: _process,
            };
          }
          return state;
        }
        case "SET_HISTORY": {
          return {
            ...state,
            history: action.payload,
          };
        }

        case "LOCK_PROCESS": {
          return {
            ...state,
            process: {
              ...state.process!,
              locked: createLockValue(
                appContext.state.loggedInUser !== undefined
                  ? appContext.state.loggedInUser!.userId
                  : "error"
              ),
            },
          };
        }

        case "UNLOCK_PROCESS": {
          return {
            ...state,
            process: {
              ...state.process!,
              locked: null,
            },
          };
        }

        case "ADD_CHANGE_NOTIFICATION": {
          return {
            ...state,
            changeNotifications: [...state.changeNotifications, action.payload],
          };
        }

        case "REMOVE_CHANGE_NOTIFICATION": {
          return {
            ...state,
            changeNotifications: state.changeNotifications.filter(
              (notification) => notification.id !== action.payload.id
            ),
          };
        }

        case "REMOVE_FILE": {
          removeFile(
            action.payload.fileName,
            action.payload.parentId,
            dispatch,
            state
          );
          return state;
        }

        default:
          return state;
      }
    },
    initialState
  );

  useEffect(() => {
    fetchDataAndInit();

    return () => {
      dispatch({ type: "SET_PROCESS", payload: null });
    };
  }, []);

  const fetchDataAndInit = async () => {
    //hopefully this takes care of the missing state issue
    await fetchProcess();
  };

  const fetchProcess = async () => {
    const _client = SuperStoreService.getInstance();
    const _process = await _client.getItem<Process>("process", processId);
    dispatch({ type: "SET_PROCESS", payload: _process });
    const _history = await _client.getItemHistory(processId);
    dispatch({ type: "SET_HISTORY", payload: _history });
  };

  const registerChangeProcessing = async () => {
    //make sure we are connected to the server
    AuthoringService.getInstance();
    //sometimes state is not updated in time and is null ffs
    await changeProcessing(dispatch, state);
  };

  useEffect(() => {
    registerChangeProcessing();
  }, [state.process]);

  const changeProcessing = async (
    dispatch: React.Dispatch<FVContextAction>,
    state: FVContextState
  ) => {
    const _authoringService = AuthoringService.getInstance();

    _authoringService.addEventHandler((event) => {
      // we don't add events for the current user

      if (event && event.userId !== appContext.state.loggedInUser?.userId && event.processId === state.process?.id) {
        
        console.log("Event from other user for the same process received");

        //check if event is for this process by filtering fields of process

        const _field = state.process?.data.content.sections
          .map((section) => [...section.fields1, ...(section.fields2 ?? [])])
          .flat()
          .find((field) => field.id === event.fieldId);

        if (_field) {
          console.log("change event received");

          // add the change event to the change notification list

          dispatch({
            type: "ADD_CHANGE_NOTIFICATION",
            payload: event,
          });
          
          dispatch({
            type: "UPDATE_FIELD_VALUE_SYSTEM",
            payload: {
              fieldId: event.fieldId,
              value: event.fieldValue,
            },
          });

          // remove the change event from the change notification list after 5 seconds

          setTimeout(() => {
            dispatch({
              type: "REMOVE_CHANGE_NOTIFICATION",
              payload: event,
            });
          }, 5000);
        }
      }
    });
  };

  return {
    state,
    dispatch,
  };
};

export const attachFile = async (
  file: File,
  processId: string,
  dispatch: React.Dispatch<FVContextAction>,
  state: FVContextState
) => {
  const _fileClient = FileService.getInstance();

  const _folder = await _fileClient.ensureFolderExists("Attachments", "root");
  const _file = await _fileClient.uploadFile(
    file,
    "file",
    _folder.id,
    state.process!.id,
    "Attachments"
  );

  dispatch({
    type: "UPDATE_PROCESS",
    payload: {
      ...state.process!,
      data: {
        ...state.process!.data,
        content: {
          ...state.process!.data.content,
          attachments: [
            ...(state.process!.data.content.attachments ?? []),
            _file,
          ],
        },
      },
    },
  });

  dispatch({
    type: "SAVE_PROCESS",
  });
};

export const removeFile = async (
  fileId: string,
  parentId: string,
  dispatch: React.Dispatch<FVContextAction>,
  state: FVContextState
) => {
  const _fileClient = FileService.getInstance();
  await _fileClient.deleteFile(fileId, parentId);
  dispatch({
    type: "UPDATE_PROCESS",
    payload: {
      ...state.process!,
      data: {
        ...state.process!.data,
        content: {
          ...state.process!.data.content,
          attachments: state.process!.data.content.attachments?.filter(
            (file) => file.fileName !== fileId
          ),
        },
      },
    },
  });

  dispatch({
    type: "SAVE_PROCESS",
  });
};
