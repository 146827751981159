/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

interface FormSectionProps {
  children?: ReactNode;
  title: string;
  id?: string;
  dueDate?: string;
  owners?: any[];
}

export default function FormSection(props: FormSectionProps) {
  //for some reason having numbers in the ids is breaking the collapse
  let sectionId: string = (props.id ? props.id : uuidv4()).replace(
    /[0-9]/g,
    ""
  );

  return (
    <div className="accordion-item">
      <h2
        className="accordion-header"
        id={`kt_accordion_1_header_${sectionId}`}
      >
        <button
          className="accordion-button fs-4 fw-bold collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#kt_accordion_1_body_${sectionId}`}
          aria-expanded="false"
          aria-controls={`kt_accordion_1_body_${sectionId}`}
        >
          {props.title}
        </button>
      </h2>
      <div
        id={`kt_accordion_1_body_${sectionId}`}
        className="accordion-collapse show"
        aria-labelledby={`kt_accordion_1_header_${sectionId}`}
        data-bs-parent={`#kt_accordion_${sectionId}`}
      >
        <div className="accordion-body">
          <div className="container">
            <div className="row">
              <div className="col-sm">{props.children![0]}</div>
              {props.children![1] && (
                <div className="col-sm">{props.children![1]}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
