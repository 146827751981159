/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { FormTemplate } from "../../../../models/FormTemplate";
import { FormField, FormSection } from "../../../../models/Process";
import { SuperStoreService } from "../../../../services/SuperStoreService";
import {
  createEmptyField,
  createEmptyProcessTemplate,
  createEmptySection,
} from "../../../../utils/templateUtils";
import { v4 as uuidv4 } from "uuid";
import { DataSet } from "../../../../models/dataset";
import { FormUser } from "../../../../models/FormUser";

// #region State Type

export type FBContextState = {
  datasets: DataSet[];
  formTemplate: FormTemplate | null;
  formControls: {
    id: string;
    type: string;
    name: string;
    description: string;
    locked?: boolean;
    options?: string[];
  }[];
};

// #endregion

// #region Action Types

export type FBContextAction =
  | {
      type: "SET_FORM_TEMPLATE";
      payload: FormTemplate;
    }
  | {
      type: "SAVE_FORM_TEMPLATE";
    }
  | {
      type: "ADD_SECTION";
      payload: {
        section: FormSection;
        position?: number;
      };
    }
  | {
      type: "UPDATE_SECTION";
      payload: {
        section: FormSection;
      };
    }
  | {
      type: "UPDATE_SECTION_NAME";
      payload: {
        sectionId: string;
        name: string;
      };
    }
  | {
      type: "UPDATE_SECTION_DESCRIPTION";
      payload: {
        sectionId: string;
        description: string;
      };
    }
  | {
      type: "UPDATE_SECTION_POSITION";
      payload: {
        sections: FormSection[];
      };
    }
  | {
      type: "DELETE_SECTION";
      payload: {
        sectionId: string;
      };
    }
  | {
      type: "DELETE_FIELD";
      payload: {
        sectionId: string;
        fieldId: string;
      };
    }
  | {
      type: "UPDATE_SECTION_FIELDS";
      payload: {
        sectionId: string;
        fields: FormField[];
        column: 1 | 2;
      };
    }
  | {
      type: "ADD_FIELD_TO_SECTION";
      payload: {
        sectionId: string;
        fieldType: string;
        column: 1 | 2;
      };
    }
  | {
      type: "UPDATE_FIELD";
      payload: {
        sectionId: string;
        field: FormField;
      };
    }
  | {
      type: "UPDATE_FORM_NAME";
      payload: {
        name: string;
      };
    }
  | {
      type: "UPDATE_FORM_DESCRIPTION";
      payload: {
        description: string;
      };
    }
  | {
      type: "SET_DATASETS";
      payload: {
        datasets: DataSet[];
      };
    }
  | {
      type: "UPDATE_SECTION_OWNERS";
      payload: {
        sectionId: string;
        owners: FormUser[];
      };
    }
  | {
      type: "UPDATE_COLUMN_NUMBER";
      payload: {
        sectionId: string;
        columnNumber: 1 | 2;
      };
    };

// #endregion

// #region Context

export type FBContextType = {
  state: FBContextState;
  dispatch: React.Dispatch<FBContextAction>;
};

const initialState: FBContextState = {
  datasets: [],
  formTemplate: createEmptyProcessTemplate(),
  formControls: [
    {
      id: uuidv4(),
      type: "paragraph",
      name: "Paragraph",
      description: "Ein statischer Text Paragraph",
      locked: true,
    },
    {
      id: uuidv4(),
      type: "textbox",
      name: "Textbox",
      description: "Eine Textbox welche man ausfuhlen kann",
      locked: false,
    },
    {
      id: uuidv4(),
      type: "dropdown",
      name: "Dropdown",
      description: "Eine List aus der man auswahlen kann.",
      options: [],
      locked: false,
    },
    {
      id: uuidv4(),
      type: "section",
      name: "Section",
      description: "Eine Sektion der Listvorlage",
    },
  ],
};

export const FBContext = React.createContext<FBContextType>({
  state: initialState,
  dispatch: () => {},
});

FBContext.displayName = "FBContext";

// #endregion

// #region Context Provider

export const useFBContext = (processId: string | null) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    if (processId) {
      const _client = SuperStoreService.getInstance();
      _client.getItem("formtemplate", processId).then((template) => {
        if (template)
          dispatch({
            type: "SET_FORM_TEMPLATE",
            payload: template as FormTemplate,
          });
      });
    }

    //fetch all the datasets for the dropdown fields

    const _client = SuperStoreService.getInstance();
    _client.getItems({ take: 100, skip: 0 }, "dataset").then((datasets) => {
      dispatch({
        type: "SET_DATASETS",
        payload: {
          datasets: datasets as DataSet[],
        },
      });
    });
  }, []);

  return {
    state,
    dispatch,
  };
};

// #endregion

// #region Reducer

const reducer = (
  state: FBContextState,
  action: FBContextAction
): FBContextState => {
  console.log("Reducer called with action: ", action);

  switch (action.type) {
    case "SET_FORM_TEMPLATE":
      return {
        ...state,
        formTemplate: action.payload,
      };
    case "SAVE_FORM_TEMPLATE":
      return saveFormTemplate(state);
    case "UPDATE_FORM_NAME":
      return updateFormName(state, action.payload);
    case "UPDATE_FORM_DESCRIPTION":
      return updateFormDescription(state, action.payload);
    case "ADD_SECTION":
      return addSection(state, action.payload);
    case "UPDATE_SECTION":
      return updateSection(state, action.payload);
    case "UPDATE_SECTION_NAME":
      return updateSectionName(state, action.payload);
    case "UPDATE_SECTION_DESCRIPTION":
      return updateSectionDescription(state, action.payload);
    case "DELETE_SECTION":
      return deleteSection(state, action.payload);
    case "DELETE_FIELD":
      return deleteField(state, action.payload);
    case "UPDATE_SECTION_POSITION":
      return updateSectionPosition(state, action.payload);
    case "UPDATE_SECTION_FIELDS":
      return updateSectionFields(state, action.payload);
    case "ADD_FIELD_TO_SECTION":
      return addFieldToSection(state, action.payload);
    case "UPDATE_FIELD":
      return updateField(state, action.payload);
    case "SET_DATASETS":
      return {
        ...state,
        datasets: action.payload.datasets,
      };
    case "UPDATE_SECTION_OWNERS":
      return updateSectionOwners(state, action.payload);
    case "UPDATE_COLUMN_NUMBER":
      return updateColumnNumber(state, action.payload);
    default:
      return state;
  }
};

// #endregion

// #region State actions logic

const saveFormTemplate = (state: FBContextState): FBContextState => {
  const _client = SuperStoreService.getInstance();
  _client.upsertItem(
    "formtemplate",
    state.formTemplate!,
    state.formTemplate!.id as string
  );
  return {
    ...state,
  };
};

const addSection = (
  state: FBContextState,
  payload: {
    section: FormSection;
    position?: number;
  }
): FBContextState => {
  const pos = payload.position || 0;

  const newSection = createEmptySection();

  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: [
            ...state.formTemplate!.data!.content!.sections!.slice(0, pos),
            newSection,
            ...state.formTemplate!.data!.content!.sections!.slice(pos),
          ],
        },
      },
    },
  };
};

const updateFormName = (
  state: FBContextState,
  payload: {
    name: string;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        name: payload.name,
      },
    },
  };
};

const updateFormDescription = (
  state: FBContextState,
  payload: {
    description: string;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        description: payload.description,
      },
    },
  };
};

const updateSection = (
  state: FBContextState,
  payload: {
    section: FormSection;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.section.id) {
                return payload.section;
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const deleteSection = (
  state: FBContextState,
  payload: {
    sectionId: string;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.filter(
            (section) => section.id !== payload.sectionId
          ),
        },
      },
    },
  };
};

const deleteField = (
  state: FBContextState,
  payload: {
    sectionId: string;
    fieldId: string;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                debugger;
                return {
                  ...section,
                  fields1: section.fields1!.filter(
                    (field) => field.id !== payload.fieldId
                  ),
                  fields2: section.fields2 !== undefined ? section.fields2!.filter(
                    (field) => field.id !== payload.fieldId 
                  ) : undefined,
                };
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const updateSectionName = (
  state: FBContextState,
  payload: {
    sectionId: string;
    name: string;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                return {
                  ...section,
                  name: payload.name,
                };
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const updateSectionDescription = (
  state: FBContextState,
  payload: {
    sectionId: string;
    description: string;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                return {
                  ...section,
                  description: payload.description,
                };
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const updateSectionPosition = (
  state: FBContextState,
  payload: {
    sections: FormSection[];
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: payload.sections,
        },
      },
    },
  };
};

const updateSectionOwners = (
  state: FBContextState,
  payload: {
    sectionId: string;
    owners: FormUser[];
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                return {
                  ...section,
                  owners: payload.owners,
                };
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const updateSectionFields = (
  state: FBContextState,
  payload: {
    sectionId: string;
    fields: FormField[];
    column: number;
  }
): FBContextState => {
  if (payload.fields && payload.fields.length > 0) {
    return {
      ...state,
      formTemplate: {
        ...state.formTemplate,
        data: {
          ...state.formTemplate?.data,
          content: {
            ...state.formTemplate?.data!.content,
            sections: state.formTemplate!.data!.content!.sections!.map(
              (section) => {
                if (section.id === payload.sectionId) {
                  return {
                    ...section,
                    fields1:
                      payload.column === 1 ? payload.fields : section.fields1,
                    fields2:
                      payload.column === 2 ? payload.fields : section.fields2,
                  };
                }
                return section;
              }
            ),
          },
        },
      },
    };
  } else {
    return {
      ...state,
    };
  }
};

const addFieldToSection = (
  state: FBContextState,
  payload: {
    sectionId: string;
    fieldType: string;
  }
): FBContextState => {
  const newField = createEmptyField(payload.fieldType);

  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                return {
                  ...section,
                  fields: [...section.fields1!, newField],
                };
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const updateField = (
  state: FBContextState,
  payload: {
    sectionId: string;
    field: FormField;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                return {
                  ...section,
                  fields1: section.fields1!.map((field) => {
                    if (field.id === payload.field.id) {
                      return payload.field;
                    }
                    return field;
                  }),
                  fields2: section.fields2
                    ? section.fields2!.map((field) => {
                        if (field.id === payload.field.id) {
                          return payload.field;
                        }
                        return field;
                      })
                    : undefined,
                };
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

const updateColumnNumber = (
  state: FBContextState,
  payload: {
    sectionId: string;
    columnNumber: number;
  }
): FBContextState => {
  return {
    ...state,
    formTemplate: {
      ...state.formTemplate,
      data: {
        ...state.formTemplate?.data,
        content: {
          ...state.formTemplate?.data!.content,
          sections: state.formTemplate!.data!.content!.sections!.map(
            (section) => {
              if (section.id === payload.sectionId) {
                if (payload.columnNumber === 1) {
                  const s = { ...section };
                  delete s.fields2;
                  return {
                    ...s,
                  };
                } else {
                  const s = { ...section };
                  s.fields2 = [];
                  return {
                    ...s,
                  };
                }
              }
              return section;
            }
          ),
        },
      },
    },
  };
};

// #endregion
