import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import ControlSelector from "./components/ControlSelector";
import SectionControl from "./components/SectionControl";
import { FBContext, useFBContext } from "./context/FormBuilderContext";
import './styles/FormBuilder.css'


export default function FormBuilderPage() {
  console.log("🐈‍⬛ FormBuilderPage - rendering");

  const navigate = useNavigate();

  const params = useParams();

  const formBuilderContext = useFBContext(params.formId ?? "");

  const handleSave = () => {
    formBuilderContext.dispatch({
      type: "SAVE_FORM_TEMPLATE",
    });
    navigate("/templates");
  };

  return (
    <div>
      <FBContext.Provider value={formBuilderContext}>
        <div>
          <div className="row">
            <div className="col-md-9" id="formbuilder">
              <div>
                <div className="accordion" id="kt_accordion_1">
                  <ReactSortable
                    list={
                      formBuilderContext.state.formTemplate!.data!.content!
                        .sections
                    }
                    setList={(newSections) => {
                      formBuilderContext.dispatch({
                        type: "UPDATE_SECTION_POSITION",
                        payload: {
                          sections: newSections,
                        },
                      });
                    }}
                    group="shared"
                    animation={150}
                    handle=".dragHandle"
                  >
                    {formBuilderContext.state.formTemplate!.data!.content!.sections.map(
                      (section, index) => {
                        return (
                          <SectionControl
                            key={section.id}
                            section={section}
                          />
                        );
                      }
                    )}
                  </ReactSortable>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div
                className="card card-custom card-stretch gutter-b"
                style={{ maxHeight: "30rem" }}
              >
                <div className="card-header border-0 pt-7">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                      Form details
                    </span>
                    <span className="text-muted mt-3 font-weight-bold font-size-lg">
                      generelle Einstellungen
                    </span>
                  </h3>
                </div>

                <div className="card-body pt-2">
                  <div className="tab-content mt-5" id="myTabList4">
                    <div className="form-group">
                      <label>Name:</label>
                      <input
                        onChange={(e) => {
                          formBuilderContext.dispatch({
                            type: "UPDATE_FORM_NAME",
                            payload: {
                              name: e.target.value,
                            },
                          });
                        }}
                        value={
                          formBuilderContext.state.formTemplate!.data!.name
                        }
                        type="text"
                        className="form-control"
                        placeholder="Enter a value"
                      />
                      <span className="form-text text-muted">
                        Names der Vorlage
                      </span>
                    </div>

                    <div className="form-group">
                      <label>Beschreibung:</label>
                      <input
                        onChange={(e) => {
                          formBuilderContext.dispatch({
                            type: "UPDATE_FORM_DESCRIPTION",
                            payload: {
                              description: e.target.value,
                            },
                          });
                        }}
                        value={
                          formBuilderContext.state.formTemplate!.data!
                            .description
                        }
                        type="text"
                        className="form-control"
                        placeholder="Enter a description"
                      />
                      <span className="form-text text-muted">
                        Kurze Beschreibung
                      </span>
                    </div>

                    <button
                      disabled={
                        formBuilderContext.state.formTemplate!.data!.name ===
                          "" ||
                        formBuilderContext.state.formTemplate!.data!
                          .description === ""
                      }
                      onClick={handleSave}
                      type="button"
                      className="btn btn-primary mr-2"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="card card-custom card-stretch gutter-b"
                style={{ maxHeight: "40rem" }}
              >
                <div className="card-header border-0 pt-7">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                      Felder hinzufugen
                    </span>
                    <span className="text-muted mt-3 font-weight-bold font-size-lg">
                      bitte aus der Liste wahlen
                    </span>
                  </h3>
                </div>

                <div className="card-body pt-2">
                  <div className="tab-content mt-5" id="toolpane">
                    <ReactSortable
                      list={formBuilderContext.state.formControls}
                      setList={() => {}}
                      group={{ name: "controls", pull: "clone" }}
                      sort={false}
                      ghostClass="sortable-ghost-custom"
                      chosenClass="sortable-chosen-custom"
                      dragClass="sortable-drag-custom"
                    >
                      { formBuilderContext.state.formControls &&
                        formBuilderContext.state.formControls.map(
                          (control) => {
                            return (
                              <ControlSelector
                                key={control.id}
                                type={control.type}
                                name={control.name}
                                description={control.description}
                              ></ControlSelector>
                            );
                          }
                        )
                      }

                    </ReactSortable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FBContext.Provider>
    </div>
  );
}
