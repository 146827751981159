import React, { ReactElement } from 'react'

interface Props {
    id?:string;
    paragraphText?:string;
}

export default function FVParagraph(props: Props): ReactElement {
    return (    
        <p style={{padding:'10px'}} className="text-black-50">{props.paragraphText?props.paragraphText:""}</p>
    )
}

