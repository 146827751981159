/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { FilesContext } from "../context/FilesContext";
import CreateFolderModal from "../modals/CreateFolderModal";
import UploadFileModal from "../modals/UploadFileModal";
import FilesBreadcrumb from "./FilesBreadcrumb";

import styles from "./FilesHeader.module.scss";

interface FilesHeaderProps {
  uploading: boolean;
}

export default function FilesHeader(props: FilesHeaderProps) {
  const context = useContext(FilesContext);

  const [showNewFolder, setShowNewFolder] = useState(false);
  const [showNewFile, setShowNewFile] = useState(false);

  return (
    <div className="card-header">
      <div className={styles.headerContainer}>
        <FilesBreadcrumb />
        <div className={styles.actions}>
          <a
            href="#"
            className="btn btn-sm btn-primary"
            onClick={() => setShowNewFile(true)}
          >
            <i className="fas fa-file"></i>
            Datei hinzufugen
          </a>
          <a
            href="#"
            className="btn btn-sm btn-primary"
            onClick={() => setShowNewFolder(true)}
          >
            <i className="fas fa-folder"></i> 
            Order erstellen
          </a>
          <div className="spinner-border" role="status" hidden={!props.uploading}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <CreateFolderModal
        show={showNewFolder}
        handleClose={() => setShowNewFolder(false)}
        handleCreate={(folderName: string) => {
          context.dispatch({ type: "CREATE_FOLDER", payload: folderName });
          setShowNewFolder(false);
        }}
      />
      <UploadFileModal
        show={showNewFile}
        handleClose={() => {
          setShowNewFile(false);
        }}
        handleCreate={(file: File) => {
          context.dispatch({ type: "ADD_FILE", payload: file });
          setShowNewFile(false);
        }}
      />
    </div>
  );
}
