/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { downloadFile, prepareFileName } from "../../../../../utils/fileUtils";
import { deleteFile, FilesContext } from "../context/FilesContext";
import styles from "./FilesWidgetRow.module.scss";

interface Props {
  name: string;
  size: string;
  date: string;
  type: "folder" | "file";
  id: string;
  link?: string;
  parentId: string;
  handleClick?: (id: string, name: string) => void;
}

export default function FilesWidgetRow(props: Props) {
  const fContext = useContext(FilesContext);

  const handleClick = (id: string, name: string) => {
    if (props.type === "folder" && props.handleClick) {
      props.handleClick(props.id, props.name);
    }
    if (props.type === "file") {
      if (props.link) {
        downloadFile(props.link);
      }
    }
  };

  const handleDelete = async (event: /* eslint-disable jsx-a11y/anchor-is-valid */
    React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

    event.stopPropagation();

    await deleteFile(
      props.name,
      props.parentId,
      fContext.state,
      fContext.dispatch
    );
  };

  return (
    <div
      className={styles.rowContainer}
      onClick={() => handleClick(props.id, props.name)}
    >
      <div
        className={styles.filename}
        ata-toggle="tooltip"
        data-placement="top"
        title={props.name}
      >
        {props.type === "file" && <i className="fas fa-file"></i>}
        {props.type === "folder" && <i className="fas fa-folder"></i>}
        <span>{prepareFileName(props.name)}</span>
      </div>
      <div>{props.type === "file" ? props.size : ""}</div>
      <div>{new Date(props.date).toLocaleDateString()}</div>
      <div className={styles.actions}>
        <div hidden={props.type === "folder"}>
          <a href="#" className="btn btn-icon btn-light-primary btn-sm">
            <i className="las la-download fs-2"></i>
          </a>
        </div>
        <div>
          <a
            onClick={(event) => handleDelete(event)}
            href="#"
            className="btn btn-icon btn-light-primary btn-sm"
          >
            <i className="las la-trash fs-2"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
