/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../../app/context/AppContext";
import { getLockValue } from "../../../../../utils/ lockUtils";

type ProcessRowProps = {
  processTitle: string;
  processType: string;
  processOwner: string;
  processDate: string;
  processProgress: number;
  processId: string;
  handleDelete: (processId: string) => void;
  processLocked: string | null | undefined;
};

export default function ProcessesRow(props: ProcessRowProps) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  //check if locked by current user or if lock is older than 10 minutes

  const isLocked = props.processLocked
    ? appContext.state.loggedInUser &&
      appContext.state.loggedInUser.userId !==
        getLockValue(props.processLocked).userId &&
      new Date().valueOf() -
        getLockValue(props.processLocked).lockDate.valueOf() <
        600000
    : false;

  return (
    <tr>
      <td className="ps-0">
        <a
          className="text-gray-800 fw-bolder text-hover-primary fs-6"
          onClick={() => {
            if (!isLocked) navigate(`/formviewer/${props.processId}`);
          }}
        >
          {props.processTitle}
        </a>
        <span className="text-muted fw-bold d-block mt-1">
          {props.processType}
        </span>
      </td>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          <span className="text-muted">{props.processOwner}</span>
        </a>
      </td>
      <td className="ps-0">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          {props.processDate}
        </a>
      </td>
      <td>
        <div className="d-flex flex-column me-3">
          <div className="d-flex align-items-center justify-content-between mb-2 w-">
            <span className="text-dark me-2 fs-8 fw-bolder">Progress</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="progress h-6px  w-100 bg-light-primary">
              <div
                className="progress-bar bg-primary"
                role="progressbar"
                style={{ width: `${props.processProgress.toString()}%` }}
                aria-valuenow={props.processProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <span className="text-muted fs-8 fw-bold ps-3 w-25">{`${Math.round(
              props.processProgress
            ).toString()}%`}</span>
          </div>
        </div>
      </td>
      <td className="text-end d-flex align-items-center justify-content-end gap-3 mt-2">
        {!isLocked && (
          <button
            onClick={() => {
              console.log("navigate");
              navigate(`/formviewer/${props.processId}`);
            }}
            className="btn btn-icon btn-light-primary btn-sm"
          >
            <i className="las la-edit fs-2"></i>
          </button>
        )}
        {isLocked && (
          <button className="btn btn-icon btn-light-primary btn-sm disabled">
            <i className="las la-lock fs-1x"></i>
          </button>
        )}

        {!isLocked && (
          <a
            onClick={() => {
              props.handleDelete(props.processId);
            }}
            href="#"
            className="btn btn-icon btn-light-primary btn-sm"
          >
            <i className="las la-trash fs-2"></i>
          </a>
        )}
      </td>
    </tr>
  );
}
