import React, { ReactElement, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015

interface Props {
  title: string;
  description?: string;
  id: string;
  value?: string;
  properties?: string[];
  confirmed: boolean;
  onChange: (fieldId: string, fieldValue: string) => void;
  onLocked: (fieldId: string, locked: boolean) => void;
}

export default function FVDropdown(props: Props): ReactElement {
  const [value, setValue] = useState(props.value ? props.value : "");
  const [locked, setLocked] = useState(
    props.confirmed ? props.confirmed : false
  );

  const fieldValueChanged = (new_value: string) => {
    props.onChange(props.id, new_value);
  };

  const onConfirmedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLocked(event.target.checked);
    props.onLocked(props.id, event.target.checked);
  };

  return (
    <div className="form-group row align-items-center p-3 my-1">
      <div className="col-lg-11">
        <label>{props.title}</label>
        <Typeahead
          id={"typeahead" + props.id}
          allowNew={false}
          onChange={(selected: string | any[]) => {
            selected.length > 0 ?? setValue(selected[0] as string);
            fieldValueChanged(selected[0] as string);
          }}
          disabled={locked}
          defaultSelected={[value]}
          options={props.properties as string[]}
          paginate={false}
        />
        <span className="form-text text-muted">
          {props.description ? props.description : ""}
        </span>
      </div>
      <div className="col-lg-1">
        <input
          className="form-check-input"
          onChange={onConfirmedChange}
          type="checkbox"
          name="select"
          checked={locked}
        />
      </div>
    </div>
  );
}
