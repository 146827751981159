import { Guid } from "guid-typescript";
import { ChatMessage } from "../models/ChatMessage";

interface IChatMessageHandler<T> {
  onMessageReceived(handler: (message: T) => void): void;
}

export class ChatService implements IChatMessageHandler<ChatMessage> {
  private client: WebSocket | null = null;
  private ackId = 0;
  private serviceId = Guid.create();
  private groupsJoined: string[] = [];
  private static instance: ChatService = new ChatService();
  private handlers: ((message: ChatMessage) => void)[] = [];

  public static getInstance() {
    return this.instance;
  }

  private serviceStatus: "connected" | "disconnected" | "connecting" =
    "disconnected";

  private constructor() {}

  onMessageReceived(handler: (message: ChatMessage) => void): void {
    //we only support one handler for now - because we only have one chat context
    this.handlers = [handler];
  }

  public async login(username: string) {
    console.log("💬 ChatService id: " + this.serviceId);

    console.log(
      "💬 Logging in: " + username + " with client: " + this.client?.readyState
    );

    //only attempt connection if the client is not already connected or connecting
    if (this.serviceStatus === "disconnected") {
      this.serviceStatus = "connecting";
      console.log("💬 Getting chat url for user: " + username);

      const ws_resp = await fetch("../api/chat/login?userid=" + username);
      const ws_data = await ws_resp.json();

      console.log("💬 Got chat url: " + ws_data.url);

      console.log("💬 Creating websocket");

      this.client = new WebSocket(ws_data.url, "json.webpubsub.azure.v1");

      this.client.onopen = () => {
        console.log("💬 Connected to websocket");
        this.serviceStatus = "connected";
      };

      this.client.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "message") {
            const message = data.data;
            this.handlers.forEach((handler) => {
              handler(message);
            });
          }
        } catch (err) {
          console.log("💬 Error parsing message: " + err);
        }
      };

      this.client.onclose = (ev) => {
        console.log("💬 Disconnected from websocket");
        this.serviceStatus = "disconnected";
      };
      this.client.onerror = (ev) => {
        console.log("💬 Error on websocket: " + ev);
        console.log(ev);
      };
    }
  }

  public async sendMessage(message: ChatMessage) {
    //we need to join the group if we haven't already
    if (!this.groupsJoined.includes(message.groupName)) {
      await this.joinGroup(message.groupName);
      this.groupsJoined.push(message.groupName);
    }

    //only send the message if we are connected
    if (this.client) {
      await fetch(
        `../api/ChatMessage?groupName=${message.groupName}&messageContent=${message.content}`
      );
    }
  }

  public async joinGroup(group: string) {
    if (this.serviceStatus === "connected" && this.client) {
      console.log("💬 Joining group: " + group);
      this.client.send(
        JSON.stringify({
          type: "joinGroup",
          group: group,
          ackId: this.ackId++,
        })
      );
    }
  }

  public async leaveGroup(group: string) {
    if (this.client) {
      this.client.send(
        JSON.stringify({
          type: "leaveGroup",
          group: group,
          ackId: this.ackId++,
        })
      );
    }
  }

  public async close() {
    if (this.client) {
      this.client.close();
    }
  }

  public async getHistory(group: string) {
    const resp = await fetch(`../api/ChatHistory/${group}`);
    const data = await resp.json();
    return data;
  }
}
