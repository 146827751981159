import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import TermItem from "./components/TermItem";
import {
  DatasetEditorContext,
  useDEContext,
} from "./context/DatasetEditorContext";

export default function DatasetEditorPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [showNew, setShowNew] = useState(false);

  const deContext = useDEContext(params.datasetId ?? null);

  const newVal = useRef<HTMLInputElement>(null);

  const handleSave = () => {
    deContext.dispatch({
      type: "SAVE_DATASET",
    });
    
    navigate("../datasets");

  };

  return (
    <DatasetEditorContext.Provider value={deContext}>
      <div className="col-xl-12">
        <div className="card card-custom">
          <div className="card-header">
            <div className="card-title">
              <span className="card-icon"></span>
              <h3 className="card-label d-flex flex-column">
                Neuen Datensatz erstellen
                <small>Begriffe fuer diesen Datensatz bearbeiten</small>
              </h3>
            </div>
            <div className="card-toolbar">
              <div className="d-flex gap-3">
                <button onClick={handleSave} className="btn btn-primary mr-2">Speichern</button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-12">
                <div
                  className="d-flex flex-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="min-w-400px">
                    <input
                      type="text"
                      className="form-control form-control-solid form-control-lg"
                      placeholder="Datensatz name"
                      value={deContext.state.dataset.data.name}
                      onChange={(e) =>
                        deContext.dispatch({
                          type: "SET_DATASET_NAME",
                          payload: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-secondary mr-2"
                      onClick={() => setShowNew(true)}
                    >
                      Begriff hinzufugen
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-xl-12">
                <ReactSortable
                  list={deContext.state.dataset.data.content}
                  setList={(newState) => {
                    deContext.dispatch({
                      type: "UPDATE_TERMS",
                      payload: newState,
                    });
                  }}
                  animation={150}
                >
                  {deContext.state.dataset.data.content.map((term, index) => {
                    return <TermItem term={term.value} index={index} />;
                  })}
                </ReactSortable>
              </div>
            </div>
          </div>
        </div>
        <Modal
          onHide={() => {
            setShowNew(false);
          }}
          show={showNew}
          centered
        >
          <Modal.Header>
            <Modal.Title>Neuer Begriff hinzufugen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="form-label">Neuer Begriff:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Begriff"
                ref={newVal}
              />
              <span className="form-text text-muted">Der neue Begfriff</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowNew(false)}>
              Schliessen
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                deContext.dispatch({
                  type: "ADD_TERM",
                  payload: {
                    term: newVal.current?.value ?? "",
                  },
                });
                setShowNew(false);
              }}
            >
              Hinzufuegen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </DatasetEditorContext.Provider>
  );
}
