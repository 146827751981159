import React, { useContext } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../helpers";
import { createProcess, TWPContext } from "../context/TWPContext";

interface NewProcessModalProps {
  show: boolean;
  handleClose: () => void;
}

export default function NewProcessModal(props: NewProcessModalProps) {
  const context = useContext(TWPContext);

  const nameRef = React.useRef<HTMLInputElement>(null);
  const templateRef = React.useRef<HTMLSelectElement>(null);

  const handleCreate = () => {
    if (nameRef.current?.value && templateRef.current?.value) {
      createProcess(context.dispatch, context.state, {
        name: nameRef.current?.value,
        templateId: templateRef.current?.value,
      });
    }
    props.handleClose();
  };

  return (
    <Modal
      id="kt_modal_1"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog-centered h-auto"
      show={props.show}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Neuen Prozess Starten</h5>
          <div
            onClick={() => props.handleClose()}
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <KTSVG
              path="/media/icons/duotone/Navigation/Close.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
        <div className="modal-body my-1">
          <label className="form-label">
            Bitten einen Namen fuer den Prozess eingeben:
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Namen eingeben"
            ref={nameRef}
          />
          <label className="form-label my-1">
            Bitten eine Prozess Vorlage waehlen:
          </label>
          <select
            className="form-select"
            aria-label="Select example"
            ref={templateRef}
          >
            <option>Auswaehlen</option>
            {context.state.templates.map((template) => {
              return (
                <option key={template.id} value={template.id}>
                  {template.data?.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={() => props.handleClose()}
          >
            Schliessen
          </button>
          <button
            onClick={() => handleCreate()}
            type="button"
            className="btn btn-primary"
          >
            Erstellen
          </button>
        </div>
      </div>
    </Modal>
  );
}
