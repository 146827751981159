export const generateNRandomColors = (n: number) => {
  const colors = [];
  for (let i = 0; i < n; i++) {
    colors.push(generateRandomColor());
  }
  return colors;
}

export const generateRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
}