/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { FBContext } from "../context/FormBuilderContext";

interface ControlSelectorProps {
  description: string;
  type: string;
  name: string;
}

export default function ControlSelector(props: ControlSelectorProps) {

  const formBuilderContext = useContext(FBContext);


  const AddControlToBuildPane = (controlType: string, position?: number) => {
    if (controlType === "section") {
      console.log("Adding section");
      formBuilderContext.dispatch({
        type: "ADD_SECTION",
        payload: {
          position: position ?? 0,
          section: {
            owners: [],
            dueDate: new Date(),
            completed: false,
            id: "section-" + Math.random(),
            name: "Neue Section",
            fields1: [],
            fields2: [],
          },
        },
      });
    }
  };

  return (
    <div>
      <div
        className="tab-pane fade show active draggable"
        id="kt_tab_list_4_2"
        style={{ maxHeight: "10rem" }}
        role="tabpanel"
        aria-labelledby="kt_tab_list_4_2"
      >
        <div className="d-flex flex-center pb-6">
          <span className="bullet bullet-bar bg-light-primary align-self-stretch mr-4 my-1"></span>

          <div className="d-flex flex-column flex-grow-1">
            <a className="text-dark-75 text-hover-primary font-weight-bolder font-size-lg mb-1">
              {props.name}
            </a>
            <span className="text-muted font-weight-bold">
              {props.description}
            </span>
          </div>

          <div className="dropdown dropdown-inline ml-2">
            <a
              onClick={() =>
                AddControlToBuildPane(props.type ? props.type : "notdefined")
              }
              className="btn btn-icon-primary btn-hover-light-primary btn-sm btn-icon"
            >
              <div className="dragHandle">
                <i className="las la-grip-lines"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
