/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteTemplate,
  TWTContext,
  useTWTContext,
} from "./context/TWTContext";
import DeleteConfirmationModal from "./modals/DeleteConfirmationModal";
import TemplateRow from "./rows/TemplateRow";

type Props = {
  className: string;
  innerPadding?: string;
};

export default function TablesWidgetTemplates(props: Props) {
  const context = useTWTContext();
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDelete = () => {
    deleteTemplate(context.dispatch, context.state, deleteId);
    setShowDeleteDialog(false);
  };

  return (
    <TWTContext.Provider value={context}>
      <div className={`card ${props.className}`}>
        {/* <!--begin::Header--> */}
        <div className={`card-header border-0 pt-5 ${props.innerPadding}`}>
          <h3 className="card-title align-items-start flex-column">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <span className="card-label fw-bolder text-dark fs-3">
                Formular Vorlagen
              </span>
              <span
                hidden={context.state.loading === false}
                className="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </div>
            <span className="text-muted mt-2 fw-bold fs-6">
              {context.state.totalCount} Vorlagen
            </span>
          </h3>
          <div className="card-toolbar">
            <div className="d-flex gap-5">
              <button
                className="btn btn-sm btn-success"
                onClick={() => navigate("../builder")}
              >
                Neue Vorlage
              </button>
              <ul className="nav nav-pills nav-pills-sm nav-light">
                <li className="nav-item d-flex gap-2">
                  <button
                    disabled={false}
                    className="btn btn-sm btn-icon btn-light"
                    onClick={() => context.dispatch({ type: "PAGE_BACKWARD" })}
                  >
                    <i className="las la-angle-left fs-2"></i>
                  </button>
                  <div
                    className="d-grid align-center text-muted fs-8"
                    style={{ alignContent: "center" }}
                  >
                    {context.state.pageNumber} /{" "}
                    {Math.floor(
                      context.state.totalCount / context.state.itemsPerPage
                    )}
                  </div>
                  <button
                    disabled={false}
                    className="btn btn-sm btn-icon btn-light"
                    onClick={() => context.dispatch({ type: "PAGE_FORWARD" })}
                  >
                    <i className="las la-angle-right fs-2"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className="tab-content mt-5" id="myTabTables1">
            {/* <!--begin::Tap pane--> */}
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_1_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_1_1"
            >
              {/* <!--begin::Table--> */}
              <div className="table-responsive">
                <table className="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th className="p-0 min-w-150px"></th>
                      <th className="p-0 min-w-100px"></th>
                      <th className="p-0 min-w-100px"></th>
                      <th className="p-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state.processes.map((process, index) => {
                      return (
                        <TemplateRow
                          key={index}
                          processTitle={process.data!.name ?? ""}
                          processType={process.data!.description ?? ""}
                          processOwner={process.data!.createdBy ?? ""}
                          processDate={new Date(
                            process.data!.created ?? ""
                          ).toLocaleDateString()}
                          processProgress={0}
                          processId={process.id!}
                          onDelete={(id: string) => {
                            setShowDeleteDialog(true);
                            setDeleteId(id);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!--end::Table--> */}
            </div>
            {/* <!--end::Tap pane--> */}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        handleDelete={() => handleDelete()}
        message="Sind sie sicher dass dieser Template geloescht werden soll?"
        show={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
      />
    </TWTContext.Provider>
  );
}
