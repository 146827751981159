import React, { useRef } from 'react'
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from '../../../../helpers/components/KTSVG';


interface UploadFileModalProps {
  show: boolean;
  handleClose: () => void;
  handleCreate: (file: File) => void;
}

export default function UploadFileModal(props: UploadFileModalProps) {


  const file  = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    if (file.current?.files) {
      props.handleCreate(file.current.files[0]);
    }
  }
  

  return (
    <Modal
    id="kt_modal_1"
    tabIndex={-1}
    aria-hidden="true"
    dialogClassName="modal-dialog-centered h-auto"
    show={props.show}
  >
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Ordner erstellen</h5>
        <div
          onClick={() => props.handleClose()}
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotone/Navigation/Close.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body">
        <div className="mb-10">
          <label className="form-label">Datei auswaehlen:</label>
          <input
            ref={file}
            type="file"
            className="form-control"
            placeholder="z.B. Daten"
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          onClick={() => props.handleClose()}
        >
          Schliessen
        </button>
        <button
          onClick={() => { handleFileUpload()}}
          type="button"
          className="btn btn-primary"
        >
          Hochladen
        </button>
      </div>
    </div>
  </Modal>
  )
}
