/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  EngageWidget5,
  ListsWidget1,
  TablesWidget1,
} from "../../../../_start/partials/widgets";
import { LastWeekStatsWidget } from "../../../../_start/partials/widgets/stats/LastWeekStatsWidget";
import { TemplateStatsWidget } from "../../../../_start/partials/widgets/stats/TemplateStatsWidget";
import TablesWidgetProcesses from "../../../../_start/partials/widgets/tables/TablesWidgetProcesses";
import { TablesWidgetUserProcesses } from "../../../../_start/partials/widgets/tables/TablesWidgetUserProcesses";
import { CreateAppModal } from "../_modals/create-app-stepper/CreateAppModal";

export const StartDashboardPage: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {/* begin::Row */}

      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <TablesWidgetProcesses className="card-stretch mb-5 mb-xxl-8" />
        </div>
      </div>

      {/* end::Row */}

      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          <TemplateStatsWidget className="card-stretch mb-5 mb-xxl-8" />
        </div>

        <div className="col-xl-8">
          <LastWeekStatsWidget className="card-stretch mb-5 mb-xxl-8" />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Modals */}
      <CreateAppModal show={show} handleClose={() => setShow(false)} />
      {/* end::Modals */}
    </>
  );
};
