import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../helpers'

interface DeleteConfirmationModalProps {
  show: boolean
  message: string
  handleClose: () => void
  handleDelete: () => void
}

export default function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
  return (
    <Modal
    id="kt_modal_1"
    tabIndex={-1}
    aria-hidden="true"
    dialogClassName="modal-dialog-centered h-auto"
    show={props.show}
  >
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Loschvorgang bestaetigen</h5>
        <div
          onClick={() => props.handleClose()}
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotone/Navigation/Close.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body">
        <p>{props.message}</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          onClick={() => props.handleClose()}
        >
          Schliessen
        </button>
        <button onClick={() => props.handleDelete()} type="button" className="btn btn-primary">
          Loschen
        </button>
      </div>
    </div>
  </Modal>
  )
}
