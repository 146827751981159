export class AuthoringService {
  private static _instance: AuthoringService;

  private static client: WebSocket | null = null;
  public static state: "connected" | "disconnected" | "connecting" =
    "disconnected";
  private eventHandlers: ((event: any) => void)[] = [];
  private static ackId = 0;

  private constructor() {
    this.connect("none");
  }

  public static getInstance() {
    if (!this._instance) {
      this._instance = new AuthoringService();
    }

    return this._instance;
  }

  private async connect(username: string) {
    const ws_resp = await fetch("../api/chat/login?userid=" + username);
    const ws_data = await ws_resp.json();

    AuthoringService.client = new WebSocket(
      ws_data.url,
      "json.webpubsub.azure.v1"
    );

    AuthoringService.client.onclose = (ev) => {
      console.log("💬 Disconnected from websocket");
      AuthoringService.state = "disconnected";
    };

    AuthoringService.client.onopen = () => {
      console.log("💬 Connected to websocket");
      AuthoringService.state = "connected";
      this.joinGroup();
    };

    AuthoringService.client.onmessage = (event) => {
      console.log("💬 Got authoring event from websocket: " + event.data);

      if (event.data)
        this.eventHandlers.forEach((handler) =>
          handler(JSON.parse(event.data).data)
        );
    };
  }

  public async joinGroup() {
    if (AuthoringService.state === "connected" && AuthoringService.client) {
      AuthoringService.client.send(
        JSON.stringify({
          type: "joinGroup",
          group: "authoring",
          ackId: AuthoringService.ackId++,
        })
      );
    }
  }

  public static async sendEvent(fieldId: string, fieldValue: string, processId: string) {
    await fetch(
      `../api/Authoring?fieldId=${fieldId}&fieldValue=${fieldValue}&processId=${processId}`,
      { method: "GET" }
    );
  }

  public addEventHandler(handler: (event: any) => void) {
    this.eventHandlers.push(handler);
  }
}
