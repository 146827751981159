/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Chart, { ChartConfiguration } from "chart.js";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { toAbsoluteUrl } from "../../../helpers";
import { StatisticsService } from "../../../../services/StatisticsService";
import { generateNRandomColors } from "../../../../utils/colorUtils";

type Props = {
  className: string;
  innerPadding?: string;
};

const TemplateStatsWidget: React.FC<Props> = ({
  className,
  innerPadding = "",
}) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);

  useEffect(() => {
    fetchStats();
    return function cleanUp() {};
  }, []);

  const fetchStats = async () => {
    const _service = StatisticsService.Instance;

    const stats = await _service.ProcessesByTemplate();

    const labels_new = stats.map((s: any) => s.label);
    const data_new = stats.map((s: any) => s.value);

    setLabels(labels_new);
    setData(data_new);

    const options = getChartOptions(labels_new, data_new);

    const element = document.getElementById(
      "kt_stats_widget_1_chart"
    ) as HTMLCanvasElement;
    if (!element) {
      return;
    }

    const ctx = element.getContext("2d");

    if (ctx) {
      new Chart(ctx, options);
    }
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div
        className={`card-header align-items-center border-0 mt-5 ${innerPadding}`}
      >
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">Prozess Templates</span>
        </h3>
        <div className="card-toolbar"></div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-12">
        {/* begin::Chart */}
        <div
          className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(
              "/media/svg/illustrations/bg-1.svg"
            )}')`,
          }}
        >
          <div className="fw-bolder fs-1 text-gray-800 position-absolute">
            {data.reduce((a, b) => a + b, 0)}
          </div>
          <canvas id="kt_stats_widget_1_chart"></canvas>
        </div>
        {/* end::Chart */}

        {/* begin::Items */}
        <div className="d-flex justify-content-around pt-18">
          {labels.splice(0, 3).map((label, index) => {
            return (
              <div className="d-flex flex-column align-items-center" key={'tmwi' + index}>
                <span className="fw-bolder text-gray-800 fs-6">
                  {Math.round(
                    (data[index] / data.reduce((a, b) => a + b, 0)) * 100
                  )}
                  %
                </span>
                <span className="fw-bolder text-gray-800 fs-6">{label}</span>
              </div>
            );
          })}
        </div>
        {/* end::Items */}
      </div>
      {/* end: Card Body */}
    </div>
  );
};

export { TemplateStatsWidget };

function getChartOptions(p_labels: string[], p_data: number[]) {


  const tooltipBgColor = getCSSVariableValue("--bs-gray-200");
  const tooltipColor = getCSSVariableValue("--bs-gray-800");

  const options: ChartConfiguration = {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: p_data,
          backgroundColor: generateNRandomColors(p_data.length),
        },
      ],
      labels: p_labels,
    },
    options: {
      cutoutPercentage: 75,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Templates",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        backgroundColor: tooltipBgColor,
        bodyFontColor: tooltipColor,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
    },
  };

  return options;
}
