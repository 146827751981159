export const createLockValue = (userId: string): string => {
  const date = new Date();
  const lockValue = `${userId}!${date.toISOString()}`;
  return lockValue;
};

export const getLockValue = (
  lock: string
): { lockDate: Date; userId: string } => {
  const [userId, lockDate] = lock.split("!");
  return { lockDate: new Date(lockDate), userId };
};
