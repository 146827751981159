/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { SiteUser } from "../../models/SiteUser";
import { UserService } from "../../services/UserService";

export type AppContextState = {
  loggedInUser: SiteUser | null;
  loading: boolean;
};

export type AppContextAction =
  | {
      type: "SET_LOGGED_IN_USER";
      payload: SiteUser | null;
    }
  | {
      type: "LOGOUT";
    }
  | {
      type: "REFRESH_LOGGED_IN_USER";
    }
  | {
      type: "SET_LOADING";
      payload: boolean;
    };

export type AppContextType = {
  state: AppContextState;
  dispatch: React.Dispatch<AppContextAction>;
};

const initialState: AppContextState = {
  loggedInUser: null,
  loading: false,
};

export const AppContext = React.createContext<AppContextType>({
  state: initialState,
  dispatch: () => {},
});

AppContext.displayName = "AppContext";

export const useAppContext = () => {
  const [state, dispatch] = React.useReducer(
    (state: AppContextState, action: AppContextAction) => {
      switch (action.type) {
        case "SET_LOGGED_IN_USER":
          return {
            ...state,
            loggedInUser: action.payload,
          };
        case "LOGOUT":
          return {
            ...state,
            loggedInUser: null,
          };
        case "REFRESH_LOGGED_IN_USER":
          checkUserLoginStatus({ state, dispatch });
          return state;
        case "SET_LOADING":
          return {
            ...state,
            loading: action.payload,
          };
        default:
          return state;
      }
    },
    initialState
  );

  useEffect(() => {
    console.log("useEffect called");
    checkUserLoginStatus({ state, dispatch });
  }, []);

  return {
    state,
    dispatch,
  };
};

const checkUserLoginStatus = async (context: AppContextType) => {
  context.dispatch({
    type: "SET_LOADING",
    payload: true,
  });

  const response = await fetch("/.auth/me");
  try {
    const data = await response.json();
    const user_data = data.clientPrincipal as SiteUser;
    const details = await getUserDetailsFromGraph();
    if (details) {
      console.log("User details from graph: ", details);
      user_data.email = details.mail;
      user_data.userDetails = details.displayName;
    }

    if (user_data) {
      context.dispatch({
        type: "SET_LOGGED_IN_USER",
        payload: user_data,
      });
    }

    context.dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  } catch (error) {
    console.log(error);
    context.dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  }
};

const getUserDetailsFromGraph = async () => {
  const _userService = UserService.getInstance();
  const userDetails = await _userService.getUserDetails();
  return userDetails;
};
