/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { SearchResult } from "../../../models/SearchResult";
import { SearchService } from "../../../services/SearchService";
import { KTSVG } from "../../helpers";
import SearchResultRow from "./components/SearchResultRow";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const ProcessSearch: React.FC<Props> = ({ show, handleClose }) => {
  const searchInput = React.useRef<HTMLInputElement>(null);

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.key === "Enter" || event.keyCode === 13) {
      const _searchService = SearchService.getInstance();
      const results = await _searchService.searchProcesses(
        searchInput.current?.value!
      );
      setSearchResults(results);
    }
  };

  useEffect(() => {
    
    return () => {
      setSearchResults([]);
    }
  }, [])
  

  return (
    <Modal
      className="bg-white w-100"
      id="kt_header_search_modal"
      aria-hidden="true"
      dialogClassName="modal-fullscreen h-auto"
      show={show}    >
      <div className="modal-content shadow-none" style={{height: '100vh'}}>
        <div className="container w-lg-800px">
          <div className="modal-header d-flex justify-content-end border-0">
            {/* begin::Close */}
            <div
              className="btn btn-icon btn-sm btn-light-primary ms-2"
              onClick={handleClose}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotone/Navigation/Close.svg"
              />
            </div>
            {/* end::Close */}
          </div>
          <div className="modal-body">
            {/* begin::Search */}
            <div className="pb-10">
              <input
                ref={searchInput}
                autoFocus
                type="text"
                className="form-control bg-transparent border-0 fs-4x text-center fw-normal"
                name="query"
                placeholder="Search..."
                onKeyUp={handleKeyDown}
              />
            </div>
            {/* end::Search */}

            {/* begin::Search Results */}
            <div style={{maxHeight: "80vh", overflow:"scroll"}}>
              {searchResults.map((result, index) => {
                return <SearchResultRow key={index} searchResult={result} handleClick={handleClose} />;
              })}
            </div>
            {/* end::Search Results */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ProcessSearch };
