/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { FVContext } from "../context/FormViewerContext";

export default function FormHistory() {
  const fvContext = useContext(FVContext);

  return (
    <div>
      {fvContext.state.history.map((item, index) => {
        return (
          <div key={index} className="d-flex p-2 gap-4">
            <div>
              <span className="fw-bolder">{item.user}</span>
              <span className="text-muted fw-bold d-block">
                {new Date(item.date).toLocaleString()}
              </span>
            </div>
            <div>
              {
                item.changes.map((change, index) => {
                  return (
                    <div key={index} className="d-flex gap-2">
                      <span className="fw-bolder">{change.name}</span>
                      <span className="text-muted fw-bold d-block">
                        {change.oldValue} - {change.newValue}
                      </span>
                    </div>
                  );
                })
              }
            </div>
          </div>
        );
      })}
    </div>
  );
}
