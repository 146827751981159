import { Paging } from "../models/paging";
import { ItemHistory } from "../models/Process";

export class SuperStoreService {
  private static instance: SuperStoreService = new SuperStoreService();

  private constructor() {
    // Private constructor
  }

  public static getInstance(): SuperStoreService {
    return SuperStoreService.instance;
  }

  public async getItems<T>(paging: Paging, type: string): Promise<T[]> {
    try {
      let url = `../api/SuperStore/items/${type}?take=${paging.take}&skip=${paging.skip}`;

      if (paging.filter && paging.filterBy) {
        url += `&filter=${paging.filter}&filterBy=${paging.filterBy}`;
      }

      if (paging.order && paging.orderBy) {
        url += `&order=${paging.order}&orderBy=${paging.orderBy}`;
      }

      const response = await fetch(url);
      const data = (await response.json()) as T[];
      return data;
    } catch (err) {
      console.log("🔴 Error in SuperStoreService.getItems: ", err);
      return [];
    }
  }

  public async getItemsCount(type: string, paging?: Paging): Promise<number> {
    try {
      let url = `../api/SuperStore/items/${type}?count=0`;

      if (paging) {
        if (paging.filter && paging.filterBy)
          url += `&filter=${paging.filter}&filterBy=${paging.filterBy}`;
      }

      const response = await fetch(url);
      const data = await response.json();
      return data[0].count;
    } catch (err) {
      console.log("🔴 Error in SuperStoreService.getItemsCount: ", err);
      return 0;
    }
  }

  public async getItem<T>(type: string, id: string): Promise<T | null> {
    try {
      const url = `../api/SuperStore/item/${type}/${id}`;
      const response = await fetch(url);
      const data = (await response.json()) as T;
      return data[0];
    } catch (err) {
      console.log("🔴 Error in SuperStoreService.getItem: ", err);
      return null;
    }
  }

  public async upsertItem<T>(
    type: string,
    item: T,
    itemId: string
  ): Promise<T | null> {
    try {
      const url = `../api/SuperStore/item/${type}/${itemId}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      });
      const data = (await response.json()) as T;
      return data;
    } catch (err) {
      console.log("🔴 Error in SuperStoreService.upsertItem: ", err);
      return null;
    }
  }

  public async deleteItem<T>(type: string, itemId: string): Promise<T | null> {
    try {
      const url = `../api/SuperStore/item/${type}/${itemId}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = (await response.json()) as T;
      return data;
    } catch (err) {
      console.log("🔴 Error in SuperStoreService.deleteItem: ", err);
      return null;
    }
  }

  public async getItemHistory(itemId: string): Promise<ItemHistory[]> {
    try {
      const url = `../api/ItemHistory/${itemId}`;
      const response = await fetch(url);
      const data = (await response.json()) as ItemHistory[];
      return data;
    } catch (err) {
      console.log("🔴 Error in SuperStoreService.getItemHistory: ", err);
      return [];
    }
  }
}
