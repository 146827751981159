/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { UserFile } from "../../../../../models/UserFile";
import { FileService } from "../../../../../services/FileService";

export type FileContextState = {
  files: UserFile[];
  loading: boolean;
  error: string;
  currentFolder: string;
  folderTree: { id: string; name: string }[];
};

export type FileContextAction = {
  type:
    | "SET_FILES"
    | "SET_LOADING"
    | "SET_ERROR"
    | "ADD_FILE"
    | "REMOVE_FILE"
    | "CREATE_FOLDER"
    | "SET_CURRENT_FOLDER";
  payload:
    | string
    | boolean
    | File
    | null
    | { file: File; fileType: string; parent: string; processId?: string }
    | UserFile[]
    | { name: string; parent: string }
    | { id: string; name: string };
};

export type FileContextType = {
  state: FileContextState;
  dispatch: React.Dispatch<FileContextAction>;
};

export const FilesContext = React.createContext<FileContextType>({
  state: {
    files: [],
    loading: false,
    error: "",
    currentFolder: "root",
    folderTree: [],
  },
  dispatch: () => {},
});

const initialState: FileContextState = {
  files: [],
  loading: false,
  error: "",
  currentFolder: "root",
  folderTree: [
    {
      id: "root",
      name: "HOME",
    },
  ],
};

export const useFileContext = () => {
  const [state, dispatch] = React.useReducer(
    (state: FileContextState, action: FileContextAction) => {
      switch (action.type) {
        case "SET_FILES":
          return {
            ...state,
            files: action.payload as UserFile[],
          };
        case "SET_LOADING":
          return {
            ...state,
            loading: action.payload as boolean,
          };
        case "SET_ERROR":
          return {
            ...state,
            error: action.payload as string,
          };
        case "ADD_FILE":
          const _client = FileService.getInstance();
          const file = action.payload as File;
          _client
            .uploadFile(
              file, 
              "file",
              state.currentFolder,
              null,
              null
            )
            .then((file) => {
              dispatch({
                type: "SET_FILES",
                payload: [...state.files, file],
              });
            });
          return state;
        case "CREATE_FOLDER":
          const _client2 = FileService.getInstance();
          _client2
            .uploadFile(
              null,
              "folder",
              state.currentFolder,
              "",
              action.payload as string
            )
            .then((file) => {
              dispatch({
                type: "SET_FILES",
                payload: [...state.files, file],
              });
            });
          return state;

        case "REMOVE_FILE":
          return {
            ...state,
            files: state.files.filter(
              (file) => file.fileName !== (action.payload as string)
            ),
          };

        case "SET_CURRENT_FOLDER":
          const actionParams = action.payload as { id: string; name: string };
          if (
            state.folderTree.filter((folder) => folder.id === actionParams.id)
              .length === 0
          ) {
            state.folderTree.push(actionParams);
          } else {
            state.folderTree = state.folderTree.slice(
              0,
              state.folderTree.findIndex(
                (folder) => folder.id === actionParams.id
              ) + 1
            );
          }

          return {
            ...state,
            currentFolder: actionParams.id as string,
          };
        default:
          return state;
      }
    },
    initialState
  );

  useEffect(() => {
    fetchFiles();
    return () => {
      // cleanup
    };
  }, [state.currentFolder]);

  const fetchFiles = async () => {
    const _client = FileService.getInstance();
    const files = await _client.getFiles(state.currentFolder);
    files.sort((a, b) => {
      if (a.type === "folder" && b.type === "file") {
        return -1;
      }
      if (a.type === "file" && b.type === "folder") {
        return 1;
      }
      return 0;
    });
    dispatch({
      type: "SET_FILES",
      payload: files,
    });
  };

  return { state, dispatch };
};


export const deleteFile = async (fileName: string, parentId: string, state: FileContextState, dispatch: React.Dispatch<FileContextAction>) => {
  const _client = FileService.getInstance();
  await _client.deleteFile(fileName, parentId);

  dispatch({
    type: "REMOVE_FILE",
    payload: fileName,
  });


}