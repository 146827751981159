/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { ReactSortable } from "react-sortablejs";
import { FormSection } from "../../../../models/Process";
import { FBContext } from "../context/FormBuilderContext";
import PeoplePicker from "./PeoplePicker";
import TextControl from "./TextControl";
import { v4 as uuidv4 } from "uuid";
import ParagraphControl from "./ParagraphControl";
import DropdownControl from "./DropdownControl";
import { FormUser } from "../../../../models/FormUser";

interface SectionControlProps {
  children?: React.ReactNode | React.ReactNode[];
  section: FormSection;
}

export default function SectionControl(props: SectionControlProps) {
  const formBuilderContext = useContext(FBContext);
  const [showSettings, setShowSettings] = useState(false);

  const sectionName = useRef<HTMLInputElement>(null);

  const [owners, setOwners] = useState<FormUser[]>([]);

  React.useEffect(() => {
    if(props.section.owners)
      setOwners(props.section.owners);
  }, [props.section.owners])
  

  const handleDelete = () => {
    formBuilderContext.dispatch({
      type: "DELETE_SECTION",
      payload: {
        sectionId: props.section.id,
      },
    });

    setShowSettings(false);
  };

  const handleShowSettings = (evt: any) => {
    evt.stopPropagation();
    setShowSettings(true);
  };

  const saveSettings = () => {
    formBuilderContext.dispatch({
      type: "UPDATE_SECTION_NAME",
      payload: {
        sectionId: props.section.id,
        name: sectionName.current?.value ?? "Empty name",
      },
    });

    formBuilderContext.dispatch({
      type: "UPDATE_SECTION_OWNERS",
      payload: {
        sectionId: props.section.id,
        owners: owners,
      },
    });

    setShowSettings(false);
  };

  const changeColumnNumber = (colNumber: 1 | 2) => {
    formBuilderContext.dispatch({
      type: "UPDATE_COLUMN_NUMBER",
      payload: {
        sectionId: props.section.id,
        columnNumber: colNumber,
      },
    });
  };

  return (
    <div className="accordion-item" section-id={props.section.id}>
      <h2
        className="accordion-header"
        id={"kt_accordion_1_header_1" + props.section?.id}
      >
        <button
          className="accordion-button fs-4 fw-bold collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#kt_accordion_1_body_1" + props.section?.id}
          aria-expanded="false"
          aria-controls="kt_accordion_1_body_1"
        >
          <div
            className="d-flex w-100 gap-5"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="d-flex gap-5">
              <div className="dragHandle">
                <i className="las la-grip-lines"></i>
              </div>
              <div>{props.section?.name}</div>
            </div>
            <div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <div
                  className={
                    props.section.fields2
                      ? "btn btn-sm btn-secondary"
                      : "btn btn-sm btn-secondary active"
                  }
                  onClick={() => changeColumnNumber(1)}
                >
                  1
                </div>
                <div
                  className={
                    props.section.fields2
                      ? "btn btn-sm btn-secondary active"
                      : "btn btn-sm btn-secondary"
                  }
                  onClick={() => changeColumnNumber(2)}
                >
                  2
                </div>
              </div>
              <div className="btn" onClick={(evt) => handleShowSettings(evt)}>
                <i className="las la-cog fs-3"></i>
              </div>
            </div>
          </div>
        </button>
      </h2>
      <div
        id={"kt_accordion_1_body_1" + props.section?.id}
        className="accordion-collapse show"
        aria-labelledby="kt_accordion_1_header_1"
      >
        <div className="accordion-body">
          <div className="d-flex flex-row gap-3">
            <div className={props.section.fields2 ? "w-50" : "w-100"}>
              <ReactSortable
                group={{
                  name: `section-${props.section?.id}-1`,
                  put: ["controls", `section-${props.section?.id}-2`],
                }}
                style={{
                  border: "1px solid #ebedf2",
                  borderRadius: "5px",
                  minHeight: "1rem",
                }}
                animation={150}
                list={props.section?.fields1 ?? []}
                setList={(newState) => {
                  formBuilderContext.dispatch({
                    type: "UPDATE_SECTION_FIELDS",
                    payload: {
                      sectionId: props.section.id,
                      fields: newState,
                      column: 1,
                    },
                  });
                }}
                clone={(el) => {
                  return {
                    ...el,
                    id: uuidv4(),
                  };
                }}
                ghostClass="sortable-ghost-custom"
                handle=".dragHandleControl"
              >
                {props.section &&
                  props.section.fields1.map((field) => {
                    switch (field.type) {
                      case "textbox":
                        return (
                          <TextControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                      case "paragraph":
                        return (
                          <ParagraphControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                      case "dropdown":
                        return (
                          <DropdownControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                      default:
                        return (
                          <TextControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                    }
                  })}
              </ReactSortable>
            </div>
            <div className={"w-50"} style={{display: props.section.fields2 ? "block" : "none"}}>
              <ReactSortable
                group={{
                  name: `section-${props.section?.id}-2`,
                  put: ["controls", `section-${props.section?.id}-1`],
                }}
                style={{
                  border: "1px solid #ebedf2",
                  borderRadius: "5px",
                  minHeight: "1rem",
                }}
                animation={150}
                list={props.section?.fields2 ?? []}
                setList={(newState) => {
                  formBuilderContext.dispatch({
                    type: "UPDATE_SECTION_FIELDS",
                    payload: {
                      sectionId: props.section.id,
                      fields: newState,
                      column: 2
                    },
                  });
                }}
                clone={(el) => {
                  return {
                    ...el,
                    id: uuidv4(),
                  };
                }}
                ghostClass="sortable-ghost-custom"
                handle=".dragHandleControl"
              >
                {props.section && props.section.fields2 &&
                  props.section.fields2.map((field) => {
                    switch (field.type) {
                      case "textbox":
                        return (
                          <TextControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                      case "paragraph":
                        return (
                          <ParagraphControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                      case "dropdown":
                        return (
                          <DropdownControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                      default:
                        return (
                          <TextControl
                            key={field.id}
                            field={field}
                            sectionId={props.section.id}
                          />
                        );
                    }
                  })}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onHide={() => {
          setShowSettings(false);
        }}
        show={showSettings}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Field settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Section title:</label>
            <input
              type="text"
              className="form-control my-3"
              placeholder="Enter value"
              ref={sectionName}
              defaultValue={props.section.name}
            />
            <label className="form-label">Assign to:</label>
            <PeoplePicker onchange={setOwners} values={owners} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSettings(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={saveSettings}>
            Save Changes
          </Button>
          <Button variant="warning" onClick={() => handleDelete()}>
            Delete section
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
