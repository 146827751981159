import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";

import { ErrorsPage } from "./modules/errors/ErrorsPage";
import { StartDashboardWrapper } from "./pages/dashboards/start-dashboard/StartDashboardWrapper";
import { AuthPage } from "./modules/auth";
import { startDashboardLoader } from "./pages/dashboards/start-dashboard/loader/StartDashboardLoader";
import { TemplateDashboardWrapper } from "./pages/dashboards/templates-dashboard/TemplateDashboardWrapper";
import { DatasetDashboardWrapper } from "./pages/dashboards/dataset-dashboard/DatasetDashboardWrapper";
import { FormViewerWrapper } from "./pages/form-viewer/FormViewerWrapper";
import { RecycleDashboardWrapper } from "./pages/dashboards/recycle-dashboard/RecycleDashboardWrapper";
import { FilesDashboardWrapper } from "./pages/dashboards/files-dashboard/FilesDashboardWrapper";
import { FormBuilderWrapper } from "./pages/form-builder/FormBuilderWrapper";
import { DatasetEditorPageWrapper } from "./pages/dataset-editor/DatasetEditorPageWrapper";
import { AppContext, useAppContext } from "./context/AppContext";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const publicRouter = createBrowserRouter([
    { path: "/", element: <AuthPage /> },
    { path: "/auth", element: <AuthPage /> },
    { path: "/errors", element: <ErrorsPage /> },
    { path: "*", element: <ErrorsPage /> },
  ]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MasterLayout></MasterLayout>,
      children: [
        {
          path: "/",
          element: <StartDashboardWrapper />,
          loader: startDashboardLoader,
        },
        {
          path: "/templates",
          element: <TemplateDashboardWrapper />,
          loader: startDashboardLoader,
        },
        {
          path: "/datasets",
          element: <DatasetDashboardWrapper />,
          loader: startDashboardLoader,
        },
        {
          path: "/formviewer/:processId",
          element: <FormViewerWrapper />,
          loader: startDashboardLoader,
        },
        {
          path: "/recycle",
          element: <RecycleDashboardWrapper />,
          loader: startDashboardLoader,
        },
        {
          path: "/files",
          element: <FilesDashboardWrapper />,
        },
        {
          path: "/builder",
          element: <FormBuilderWrapper />,
        },
        {
          path: "/builder/:formId",
          element: <FormBuilderWrapper />,
        },
        {
          path: "/dataseteditor",
          element: <DatasetEditorPageWrapper />,
        },
        {
          path: "/dataseteditor/:datasetId",
          element: <DatasetEditorPageWrapper />,
        },
        { path: "*", element: <ErrorsPage /> },
      ],
    },
    { path: "/logout", element: <AuthPage /> },
  ]);

  const appContext = useAppContext();

  return (
    <ThemeProvider>
      <AppContext.Provider value={appContext}>
        {!appContext.state.loading ? (
          <RouterProvider
            router={
              appContext.state.loggedInUser !== null ? router : publicRouter
            }
          />
        ) : (
          <div id="splash-screen" className="splash-screen">
            <img
              src="./media/logos/logo-compact.svg"
              alt="Start logo"
            />
            <span>Loading ...</span>
          </div>
        )}
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export { App };
