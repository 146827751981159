import { UserFile } from "../models/UserFile";

export class FileService {
  public static instance: FileService;

  public static getInstance(): FileService {
    if (!FileService.instance) {
      FileService.instance = new FileService();
    }
    return FileService.instance;
  }

  public async uploadFile(
    file: File | null,
    fileType: string,
    parent: string,
    processId: string | null,
    folderName: string | null
  ): Promise<UserFile> {
    const formData = new FormData();
    formData.append("file", file ?? "");

    let url = "/api/file?fileType=" + fileType + "&parentId=" + parent;
    if (processId) {
      url += "&processId=" + processId;
    }
    if (folderName) {
      url += "&folderName=" + folderName;
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    let data = await response.json();

    return data as UserFile;
  }

  public async ensureFolderExists(
    folderName: string,
    parent: string
  ): Promise<UserFile> {
    let existingFiles = await this.getFiles(parent);

    let existingFolder = existingFiles.find((f) => f.fileName === folderName);

    if (existingFolder) {
      return existingFolder;
    } else {
      return await this.uploadFile(null, "folder", parent, null, folderName);
    }
  }

  public async getFiles(parent: string): Promise<UserFile[]> {
    let response = await fetch("/api/files/" + parent);
    let data = await response.json();
    return data as UserFile[];
  }

  public async deleteFile(fileId: string, parentId: string): Promise<void> {
    await fetch(`/api/file?folderName=${fileId}&parentId=${parentId}`, {
      method: "DELETE",
    });
  }
}
