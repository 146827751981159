/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { FormField } from "../../../../models/Process";
import { FBContext } from "../context/FormBuilderContext";
import TermsetPicker from "./TermsetPicker";

interface DropdownControlProps {
  sectionId: string;
  field: FormField;
}

export default function DropdownControl(props: DropdownControlProps) {

  const [displaySettings, setDisplaySettings] = useState(false);
  const formBuilderContext = useContext(FBContext);
  const [options, setOptions] = useState<string[]>(props.field.options ?? []);
  
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const onSave = () => {
    formBuilderContext.dispatch({
      type: "UPDATE_FIELD",
      payload: {
        sectionId: props.sectionId,
        field: {
          ...props.field,
          options: options,
          name: nameRef.current?.value ?? "Empty field",
          description: descriptionRef.current?.value ?? "Empty field",
        },
      },
    });
  };

  const handleDelete = () => {
    formBuilderContext.dispatch({
      type: "DELETE_FIELD",
      payload: {
        sectionId: props.sectionId,
        fieldId: props.field.id,
      },
    });
  };

  return (
    
    <div>
      <div
        className="card card-custom draggable"
        style={{ marginBottom: "2rem" }}
      >
        <div className="card-body" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-11">
                <div
                  className="d-flex flex-column"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="form-group">
                    <label className="form-label">{props.field.name}</label>
                    <select className="form-control" id="exampleSelect1">
                      {props.field.options.map((option: any) => {
                        return <option>{option}</option>;
                      })}
                    </select>
                    <span className="form-text text-muted">
                      {props.field.description}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-1">
                <div className="d-flex flex-column">
                  <div className="p-2 dragHandleControl">
                    <button className="btn btn-sm btn-icon">
                      <i className="las la-bars fs-3"></i>
                    </button>
                  </div>

                  <div className="p-2">
                    <a
                      className="btn btn-sm btn-icon"
                      onClick={() => setDisplaySettings(true)}
                    >
                      <i className="las la-cog fs-3"></i>
                    </a>
                  </div>
                  <div className="p-2">
                    <a className="btn btn-sm btn-icon" onClick={() => handleDelete()}>
                      <i className="las la-trash fs-3"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={displaySettings} centered>
        <Modal.Header closeButton>
          <Modal.Title>Field settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">Control label:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter value"
              defaultValue={props.field.name ?? "no name selected"}
              ref={nameRef}
            />
            <span className="form-text text-muted">
              Label text for this control
            </span>
          </div>

          <div className="form-group">
            <label className="form-label">Control description:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter value"
              ref={descriptionRef}
              defaultValue={
                props.field.description ?? "no description selected"
              }
            />
            <span className="form-text text-muted">
              A description for the field
            </span>
          </div>

          <div className="form-group">
            <label className="form-label">Dropdown values</label>
              <TermsetPicker options={options} handleOptionsChange={setOptions} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDisplaySettings(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => onSave()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
