/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ChatMessageElement from "./components/ChatMessageElement";
import { ChatContext, useChatContext } from "./context/ChatContext";

export default function DrawerChat() {
  const chatContext = useChatContext();

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const messagesRef = useRef<HTMLDivElement | null>(null);

  const [messageText, setMessageText] = useState("");

  const sendMessage = () => {
    if (messageText && messageText !== "" && messageText !== "\n") {
      console.log("sending message");
      chatContext.sendMessage(messageText);
      setMessageText("");
    }
  };

  useEffect(() => {
    if (scrollRef.current && messagesRef.current) {
      scrollRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [chatContext.chatMessages]);

  return (
    <ChatContext.Provider value={chatContext}>
      <div className="flex-lg-row-fluid" id="kt_chat_content">
        {/* begin::Card*/}
        <div className="card card-custom">
          {/* begin::Header*/}

          {/* end::Header*/}

          {/* begin::Body*/}
          <div className="card-body px-0 mx-1">
            {/* begin::Scroll*/}
            <div
              ref={scrollRef}
              className="scroll-y me-lg-n6 pe-lg-5"
              data-kt-scroll="true"
              data-kt-scroll-height="{'default' : '400px', 'lg' : 'auto'}"
              data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_content_header, #kt_chat_content_footer"
              data-kt-scroll-wrappers="#kt_content, #kt_wrapper"
              data-kt-scroll-offset="{'default' : '10px', 'lg' : '52px'}"
            >
              {/* begin::Messages*/}
              <div className="messages" id="allMessages" ref={messagesRef}>
                {chatContext.chatMessages.map((msg, index) => {
                  return (
                    <ChatMessageElement msg={msg} index={index} key={index} />
                  );
                })}
              </div>
              {/* end::Messages*/}
            </div>
            {/* end::Scroll*/}
          </div>
          {/* end::Body*/}

          {/* begin::Footer*/}
          <div
            className="card-footer align-items-center px-7 py-4"
            id="kt_chat_content_footer"
          >
            {/* begin::Compose*/}
            <div className="position-relative">
              <textarea
                className="form-control border-0 p-2 resize-none overflow-hidden"
                rows={1}
                placeholder="Reply..."
                onChange={(e) => setMessageText(e.target.value)}
                value={messageText}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
              />
              <div className="position-absolute top-0 end-0 mr-n2">
                <span className="btn btn-icon btn-sm btn-active-light-primary">
                  <i className="fas fa-paperclip fs-4"></i>
                </span>
              </div>
            </div>
            {/* begin::Compose*/}
          </div>
          {/* end::Footer*/}
        </div>
        {/* end::Card*/}
      </div>
    </ChatContext.Provider>
  );
}
