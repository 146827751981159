/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import FilesHeader from "./components/FilesHeader";
import { FilesContext, useFileContext } from "./context/FilesContext";
import FilesWidgetRow from "./rows/FilesWidgetRow";
import { useDropzone } from "react-dropzone";

export default function FilesWidget() {
  const context = useFileContext();

  const [uploading, setUploading] = useState(false);

  const handleFolderClick = (id: string, name: string) => {
    context.dispatch({
      type: "SET_CURRENT_FOLDER",
      payload: {
        id: id,
        name: name,
      },
    });
  };

  const handleBackClick = () => {
    const folderTree = context.state.folderTree;
    const beforeLast = folderTree[folderTree.length - 2];

    context.dispatch({
      type: "SET_CURRENT_FOLDER",
      payload: {
        id: beforeLast.id,
        name: beforeLast.name,
      },
    });
  };

  const onDrop = useCallback(async (acceptedFiles: any) => {
    // Do something with the files
    console.log(acceptedFiles);

    setUploading(true);

    await acceptedFiles.forEach((file: any) => {
      context.dispatch({ type: "ADD_FILE", payload: file });
    });

    setUploading(false);

  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <FilesContext.Provider value={context}>
      <div className={"card card-flush p-5"} style={{ height: "80vh" }}>
        <div {...getRootProps()}>
          <FilesHeader uploading={uploading} />
          <div className="card-body">
            {context.state.currentFolder !== "root" && (
              <FilesWidgetRow
                name={".."}
                size={"1mb"}
                date={"2021-01-01"}
                type={"folder"}
                parentId={context.state.currentFolder}
                id={context.state.currentFolder}
                handleClick={handleBackClick}
              />
            )}

            {context.state.files.map((file, index) => {
              return (
                <FilesWidgetRow
                  parentId={context.state.currentFolder}
                  key={index}
                  name={file.fileName}
                  size={"1mb"}
                  date={file.created as string}
                  type={file.type}
                  id={file.id}
                  handleClick={handleFolderClick}
                  link={file.link}
                />
              );
            })}
          </div>
        </div>
      </div>
    </FilesContext.Provider>
  );
}
