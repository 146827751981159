/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { UserFile } from "../../../../models/UserFile";
import { downloadFile } from "../../../../utils/fileUtils";
import { KTSVG } from "../../../../_start/helpers";
import { FVContext, removeFile } from "../context/FormViewerContext";
import DeleteConfirmationModal from "../../../../_start/partials/widgets/tables/modals/DeleteConfirmationModal";

interface FileElementProps {
  file: UserFile;
}

export default function FileElement(props: FileElementProps) {
  const fvContext = useContext(FVContext);

  const deleteFile = () => {
    removeFile(
      props.file.fileName,
      props.file.parentId,
      fvContext.dispatch,
      fvContext.state
    );
  };

  const [showDeleteConfirmation, setshowDeleteConfirmation] =
    React.useState(false);

  return (
    <div
      className="card card-custom overlay overflow-hidden"
      style={{ minWidth: "10rem" }}
    >
      <div className="card-body p-0">
        <div className="overlay-wrapper">
          <div className="d-flex flex-column gap-2 align-items-center">
            <KTSVG
              className="svg-icon-2x ms-n1"
              path="/media/icons/duotone/Files/Compiled-file.svg"
            />
            <div className="mx-4 fs-7">{props.file.fileName.slice(0, 25)}</div>
          </div>
        </div>
        <div className="overlay-layer bg-dark bg-opacity-10">
          <a
            onClick={() => {
              downloadFile(props.file.link);
            }}
            href="#"
            className="btn btn-sm btn-primary btn-shadow"
          >
            <i className="las la-download fs-2"></i>
          </a>
          <a
            onClick={() => setshowDeleteConfirmation(true)}
            href="#"
            className="btn btn-sm btn-light-primary btn-shadow ms-2"
          >
            <i className="las la-trash fs-2"></i>
          </a>
        </div>
      </div>
      <DeleteConfirmationModal
        handleDelete={deleteFile}
        message={`Sind sie sicher dass die Datei '${props.file.fileName}' geloescht werden soll?`}
        show={showDeleteConfirmation}
        handleClose={() => setshowDeleteConfirmation(false)}
      />
    </div>
  );
}
