/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Dropdown2 } from "../../content/dropdown/Dropdown2";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { StatisticsService } from "../../../../services/StatisticsService";
import TimeSeries from "ts-timeseries";

type Props = {
  className: string;
  innerPadding?: string;
};

const LastWeekStatsWidget: React.FC<Props> = ({
  className,
  innerPadding = "",
}) => {
  const [activeTab, setActiveTab] = useState("#tab1");
  const [activeChart, setActiveChart] = useState<ApexCharts | undefined>();
  useEffect(() => {
    setTab(1);
    return function cleanUp() {
      if (activeChart) {
        activeChart.destroy();
      }
    };
  }, []);

  const setTab = async (tabNumber: number) => {
    const element = document.querySelector(
      `#tab${tabNumber}_chart`
    ) as HTMLElement;
    if (!element) {
      return;
    }

    const _service = StatisticsService.Instance;
    const data = await _service.GetLast30Days();

    const timeSeries = new TimeSeries("test", data.map((d:any) => [new Date(d.label), d.value]), "daily");

    const filledTimeSeries = timeSeries.fillGaps();

    const height = parseInt(getCss(element, "height"));
    const chart = new ApexCharts(element, getChartOptions(tabNumber, height, filledTimeSeries.getValues(), filledTimeSeries.getDates()));
    chart.render();
    setActiveChart(chart);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header align-items-center border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">30 Tage Uberblick</span>
          <span className="text-muted mt-2 fw-bold fs-6"></span>
        </h3>
        <div className="card-toolbar"></div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-0">
        <div className="d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-md-start pt-4">
          {/* begin::Tab Content */}
          <div
            className="tab-content flex-grow-1" // style={{ paddingLeft: "0.23rem !important" }}
          >
            {/* begin::Tab Pane 1 */}
            <div 
              className={`tab-pane fade ${
                activeTab === "#tab1" ? "show active" : ""
              }`}
              id="tab1_content"
            >
              {/* begin::Chart */}
              <div id="tab1_chart" style={{ height: "250px" }} />
              {/* end::Chart      */}
            </div>
            {/* end::Tab Pane 1 */}
          </div>
          {/* end::Tab Content */}
        </div>
      </div>
      {/* end: Card Body */}
    </div>
  );
};

export { LastWeekStatsWidget };

function getChartOptions(
  tabNumber: number,
  height: string | number | undefined,
  values: number[],
  dates: Date[]
): ApexOptions {
  return {
    series: [
      {
        name: "Prozesse",
        data: values,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: dates.map((d) => d.toLocaleDateString()),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: getCSSVariableValue("--bs-gray-700"),
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: getCSSVariableValue("--bs-gray-700"),
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val: number) {
          return `${val} Prozesse`;
        },
      },
    },
    colors: [
      getCSSVariableValue("--bs-primary"),
      getCSSVariableValue("--bs-light-primary"),
    ],
    grid: {
      borderColor: getCSSVariableValue("--bs-gray-200"),
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}


function getCss(el: HTMLElement, styleProp: string) {
  const defaultView = (el.ownerDocument || document).defaultView;
  if (!defaultView) {
    return "";
  }

  // sanitize property name to css notation
  // (hyphen separated words eg. font-Size)
  styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
}
