export const downloadFile = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileUrl);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const prepareFileName = (fileName: string) : string => {

  //truncate long file names (larger than 20 characters) and add the extension

  const fileExtension = getFileExtension(fileName);

  if (fileName.length > 20) {
    return fileName.substring(0, 17) + '...' + fileExtension;
  }
  return fileName;


}

export const getFileExtension = (fileName: string) : string => {
  //will return the extension of the file 
  //ex: .pdf, .docx, .xlsx, .jpg, .png

  const fileExtension = fileName.split(".").pop();

  if (fileExtension === undefined) {
    return "";
  }
  return fileExtension;

}

