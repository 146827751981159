/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TablesWidgetTemplates from "../../../../_start/partials/widgets/tables/TablesWidgetTemplates";

export const TemplateDashboardPage: React.FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div className="col-xl-12">
        <TablesWidgetTemplates className="card-stretch mb-5 mb-xxl-8" />
      </div>
    </>
  );
};
