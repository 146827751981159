/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../../../../app/context/AppContext";
import { ChatMessage } from "../../../../../../models/ChatMessage";
import { ChatService } from "../../../../../../services/ChatService";
import { calculateRoomFromPath } from "../../../../../../utils/chatUtils";

type ChatContextType = {
  chatMessages: ChatMessage[];
  sendMessage: (message: string, group: string) => void;
  currentRoom: string;
};

export const ChatContext = React.createContext<ChatContextType>({
  chatMessages: [],
  sendMessage: (message: string, group: string) => {},
  currentRoom: "general",
});

ChatContext.displayName = "ChatContext";

export const useChatContext = () => {
  const appContext = useAppContext();

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  
  const _chatService: ChatService = ChatService.getInstance();

  const [currentRoom, setCurrentRoom] = useState("");

  let location = useLocation();

  useEffect(() => {

    if (true && appContext.state.loggedInUser?.userId) {
      _chatService.login(appContext.state.loggedInUser?.userId).catch((err) => {
        console.log("Error logging in: " + err);
      });

      _chatService.onMessageReceived((message: ChatMessage) => {
        console.log("💬 Received message: " + message);
        setChatMessages((prevMessages) => [...prevMessages, message]);
      });
    }

    return () => {
      console.log("Closing socket from useEffect");
      _chatService.close();
    };
  }, [appContext.state.loggedInUser]);

  useEffect(() => {
    getMessagesForRoom(calculateRoomFromPath(location.pathname));
    setCurrentRoom(calculateRoomFromPath(location.pathname));

    return () => {
      setChatMessages([]);
    };
  }, [location.pathname]);

  const sendMessage = (message: string) => {
    const group = calculateRoomFromPath(location.pathname);

    _chatService.sendMessage({
      content: message,
      groupName: group,
      username: appContext.state.loggedInUser?.userDetails ?? "Unknown",
      type: "message",
    });
  };

  const getMessagesForRoom = async (room: string) => {
    const messages = await _chatService.getHistory(room);
    setChatMessages(messages);
  };

  return {
    sendMessage,
    chatMessages,
    currentRoom
  };
};
